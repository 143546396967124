import { Component, OnInit, TemplateRef, Inject, NgZone, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';
import { Category } from '../../interfaces/models/category';
import { CategoriesService } from '../../services/categories.service';

@Component({
    selector: 'app-modal-remove-category',
    templateUrl: 'modal-remove-category.html',
})
export class ModalRemoveCategoryComponent {

    category: Category;
    @Output() onDelete = new EventEmitter<Category>();
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveCategoryComponent>,
        private categoriesService: CategoriesService,
        private notificationService: NotificationService,
        private zone: NgZone,
        public router: Router) {
        this.category = data.category;
    }

    removeCategory(category: Category) {
        this.deleteCategory(category);
        this.dialogRef.close();
    }

    modalRemoveCategoryCancel() {
        this.dialogRef.close();
    }

    public deleteCategory(category?: Category) {
        const categoryToDelete = category ? category : this.category;
        this.categoriesService.deleteCategory(category).subscribe(
            result => {
                this.notificationService.notify("Category deleted successfully",
                    ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
                this.onDelete.emit(categoryToDelete);
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

