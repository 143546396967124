<div class="home">

  <div fxLayout="column" fxLayoutAlign="center center" class="logo-background-container">
    <img src="./../../assets/img/creatorLogo.png" class="logo-background" />
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill *ngIf="accessExercises || pavigymHome">
    <h5 fxFlex="nogrow">MAIN ACTIONS</h5>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" *ngIf="accessExercises">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">EXERCISES</h5>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="25">
              <mat-icon class="icon-big">fitness_center</mat-icon>
            </div>
            <div fxLayout="column" fxLayout.xs="column" fxFlex="75">
              <p>Here you can view and edit all exercises and also <a class="inline-link" [routerLink]="['/exercise/new']">create
                  a new one <mat-icon>launch</mat-icon></a></p>
              <p>There are currently {{exerciseList.length}} exercises available.</p>

              <mat-form-field>
                <input type="text" placeholder="Quick search exercise ..." matInput [formControl]="exerciseCtrl"
                  [matAutocomplete]="autoExercise">
                <mat-autocomplete autoActiveFirstOption #autoExercise="matAutocomplete" (optionSelected)="selectExercise($event)">
                  <mat-option *ngFor="let exercise of filteredExercises | async" [value]="exercise.id">
                    <span>{{exercise.name}}</span>
                  </mat-option>
                </mat-autocomplete>
                <span uk-icon="search" class="search-icon"></span>
              </mat-form-field>

            </div>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button" [routerLink]="['/exercises']">
            GO TO EXERCISES <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" *ngIf="pavigymHome">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">SESSIONS</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="row" fxLayout.xs="column">

            <div fxLayout="column" fxFlex="25">
              <mat-icon class="icon-big">swap_calls</mat-icon>
            </div>
            <div fxLayout="column" fxLayout.xs="column" fxFlex="75">
              <p>View or edit the sessions from here or <a class="inline-link" [routerLink]="['/session/new']">create a
                  new one <mat-icon>launch</mat-icon></a></p>
              <p>There are currently {{sessionList.length}} sessions available.</p>

              <mat-form-field>
                <input type="text" placeholder="Quick search session ..." matInput [formControl]="sessionCtrl"
                  [matAutocomplete]="autoSession">
                <mat-autocomplete autoActiveFirstOption #autoSession="matAutocomplete" (optionSelected)="selectSession($event)">
                  <mat-option *ngFor="let session of filteredSessions | async" [value]="session.id">
                    <span>{{session.name}}</span>
                  </mat-option>
                </mat-autocomplete>
                <span uk-icon="search" class="search-icon"></span>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/sessions']">
            GO TO SESSIONS <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" *ngIf="pavigymHome">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">TIMELINES</h5>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayout.xs="column">

            <div fxLayout="column" fxFlex="25">
              <mat-icon class="icon-big">av_timer</mat-icon>
            </div>
            <div fxLayout="column" fxLayout.xs="column" fxFlex="75">
              <p>View or edit the timelines from here or <a class="inline-link" [routerLink]="['/timeline/new']">create
                  a new one</a>.</p>
              <p>There are currently {{timelineList.length}} timelines available.</p>

              <mat-form-field>
                <input type="text" placeholder="Quick search timeline ..." matInput [formControl]="timelineCtrl"
                  [matAutocomplete]="autoTimeline">
                <mat-autocomplete autoActiveFirstOption #autoTimeline="matAutocomplete" (optionSelected)="selectTimeline($event)">
                  <mat-option *ngFor="let timeline of filteredTimelines | async" [value]="timeline.id">
                    <span>{{timeline.name}}</span>
                  </mat-option>
                </mat-autocomplete>
                <span uk-icon="search" class="search-icon"></span>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/timelines']">
            GO TO TIMELINES <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="stretch" fxFill *ngIf="pavigymHome">
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">PROGRAMS</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Change the configuration of each program timelines: make them active or inactive, view current status
              and
              select the dates that make the timeline available. Also you can view all exercises from an specific
              program.</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/programs']">
            GO TO PROGRAMS <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">BASICS</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Select wich exercises or timelines are or not basic. You can activate them from here. Also you can view
              statistics like the number of basic exercises or timelines by program or by station.</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/basics']">
            GO TO BASICS <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

</div>


<!-- USERS ICON -->
<!-- <a href="http://devmanager.pramacloud.com" target="_blank">
  <div class="home-section uk-width-1-5@s">
    <div class="home-section-header">
      <span class="home-label"> Manager <i class="fa fa-external-link"></i></span>
    </div>

    <div class="home-section-icon">
      <i class="fa fa-cogs home-icon"></i>
    </div>
  </div>
</a> -->