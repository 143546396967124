<form (ngSubmit)="onSubmit()" #siteForm="ngForm" onkeydown="return event.key != 'Enter';">

    <h1>SESSION CREATOR</h1>
  
    <!-- SESSION INFO -->
    <div fxLayout="row" fxLayout.md="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
      <div fxLayout="column" fxFlex="40" fxFlexAlign="stretch">
        <h5 fxFlex="nogrow">MAIN INFO</h5>
        <mat-card fxFlex>
          <div *ngIf="workout.id !== 0" fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="end start">
            <h3 class="color-white">#{{workout.id}}</h3>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
            <div fxLayout="column" fxFlex="50">
              <!-- NAME -->
              <mat-form-field>
                <input matInput #sessionName placeholder="Name" [(ngModel)]="workout.name?.resources[languageService.getLangIndex(workout.name)].value"
                  name="sessionName" required>
                <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !workout.name">
                  Name is required
                </mat-hint> -->
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="50">
              <!-- CATEGORIES -->
              <mat-form-field *ngIf="!authService.isInRole(98)">
                <mat-label>Category</mat-label>
                <mat-select [(ngModel)]="workout.category" name="workoutCategory" [compareWith]="compare" (selectionChange)="workout.program = null">
                  <mat-option *ngFor="let category of categoriesService.convertTreeToList(categories)" [value]="{id: category.id}">
                    {{category.fullpath}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- PROGRAMS -->
              <mat-form-field *ngIf="authService.isInRole(98)">
                <mat-label>Program</mat-label>
                <mat-select [(ngModel)]="workout.program" name="workoutProgram" [compareWith]="compare" (selectionChange)="workout.category = null">
                  <mat-option *ngFor="let program of programs" [value]="{id: program.id}">
                    {{program.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              
            </div>
            <!-- <div fxLayout="column" fxFlex="50%"> -->
              <!-- PROGRAM-->
              <!-- <mat-form-field>
                <mat-select #sessionProgram placeholder="Program" (selectionChange)="changeProgram()"
                  [compareWith]="compare" [(ngModel)]="workout.program" name="sessionProgram" required>
                  <mat-option *ngFor="let program of programs" [value]="program">
                    {{program.name}}
                  </mat-option>
                </mat-select>            
              </mat-form-field> -->
            <!-- </div> -->
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <!-- DESCRIPTION -->
            <mat-form-field fxFill>
              <textarea matInput #sessionDescription placeholder="Description" 
              [(ngModel)]="workout.description?.resources[languageService.getLangIndex(workout.description)].value" name="sessionDescription" required></textarea>
              <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !workout.description">
                Description is required
              </mat-hint> -->
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayout.xs="column">
            <!-- <mat-checkbox [(ngModel)]="item.active" name="executionMode-{{item.id}}"
              *ngFor="let item of workout.executionModes">
              {{item.name}}
            </mat-checkbox> -->
          </div>
        </mat-card>
  
      </div>
  
      <div fxLayout="column" fxFlex="60" fxFlexAlign="stretch" class="posrel">
  
        <h5 fxFlex="nogrow">SHARED CONTENT</h5>
        <mat-card fxFlex>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
            <shared-content class="w-100" [sharedContent]="sharedContent"></shared-content>
          </div>
        </mat-card>
  
      </div>

      <!-- <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch" class="posrel">
  
        <h5 fxFlex="nogrow">IMAGE</h5>
        <mat-card fxFlex class="nopadding">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill> -->
            <!-- <image-uploader [imageName]="this.workout.image" [mediaUrl]="this.mediaUrl"
              (selectedImageEvent)="imageSelected($event)" (deletedImageEvent)="imageRemoved()" [formField]="" fxFill>
            </image-uploader> -->
          <!-- </div>
        </mat-card>
  
      </div> -->
    </div>
  
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
      <mat-toolbar class="mat-buttons-toolbar">
        <mat-toolbar-row>
          <span class="mat-spacer"></span>
          <button mat-flat-button type="button" class="prama-button full-button medium-button" title="Clone this workout to another" (click)="cloneWorkout()" *ngIf="workout.id > 0" [disabled]="saving">
            <mat-icon>file_copy</mat-icon> CLONE
          </button>
          <span class="pr-3"></span>
          <button mat-flat-button type="submit" class="prama-button full-button medium-button" title="Save workout changes" [disabled]="saving">
            <mat-icon>save</mat-icon> SAVE
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
      <block-editor [workout]="workout" [programs]="programs" [exercises]="exercises" class="w-100"></block-editor>
    </div>
  
    <br>
  
  </form>
