<form #siteForm="ngForm" class="pending-timeline">

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="start center" fxFill>
    <a mat-menu-item [routerLink]="['/pending-timelines']" [queryParams]="{filter: 'pending'}">PENDING</a>
    <a mat-menu-item [routerLink]="['/pending-timelines']" [queryParams]="{filter: 'validated'}">VALIDATED</a>
    <a mat-menu-item [routerLink]="['/pending-timelines']" [queryParams]="{filter: 'rejected'}">REJECTED</a>
    <a mat-menu-item [routerLink]="['/pending-timelines']" [queryParams]="{filter: 'disabled'}">DISABLED</a>
  </div>

  <h1>PENDING TIMELINE</h1>
  <!-- TIMELINE INFO -->
  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">MAIN INFO</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="100">
            <!-- NAME -->
            <mat-form-field>
              <input matInput #timelineName placeholder="Name" [value]="timeline.name" [(ngModel)]="timeline.name" name="timelineName" required>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="100">
            <!-- PROGRAM-->
            <div class="mt-1">
              <label>{{timeline.session.program.name}}</label>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <!-- DESCRIPTION -->
          <mat-form-field fxFill>
            <textarea matInput #timelineDescription placeholder="Description" [value]="timeline.description" [(ngModel)]="timeline.description" name="timelineDescription" required></textarea>
          </mat-form-field>
        </div>
      </mat-card>

    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">CATEGORIES</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="100">
            <label>Select a category for this timeline before validating</label>
            <div class="categories-container">
              <div *ngFor="let category of categoriesList">
                <mat-checkbox name="category-{{category.id}}" *ngIf="category.isLastNode" [checked]="isChecked(category, timelineValidation.categories)" (change)="toggleChecked(category, timelineValidation.categories, $event)">
                  {{category.fullpath}}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">Timeline LOG</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="100">
            <label>Timeline change history:</label>
            <div class="log-container">
              <div *ngIf="timelineLog.length == 0; else showLog">
                Still no logged changes
              </div>
              <ng-template #showLog>
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start" *ngFor="let logItem of timelineLog" class="mb-3">
                  <mat-icon class="m-2">access_time_icon</mat-icon>
                  <div>
                    <span class="subtitle">
                      <span>
                        <strong>
                          {{ logItem.date | date: 'medium' }}:
                        </strong>
                      </span>
                      <br>
                      <span>
                        {{ logItem.description }}
                      </span>
                    </span>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <mat-toolbar class="mat-buttons-toolbar">
      <mat-toolbar-row>
        <span class="mat-spacer"></span>
        <!-- <button mat-flat-button type="button" class="prama-button full-button" (click)="changeStatus('pending');"
          *ngIf="timeline.status === 3 || timeline.status === 0 || timeline.status === 1">
          DEBUG-REACTIVATE
        </button> -->
        <button mat-flat-button type="button" class="prama-button-red full-button" (click)="changeStatus('rejected');" *ngIf="timeline.status === 2">
          <mat-icon>cancel</mat-icon> REJECT
        </button>
        <button mat-flat-button type="button" class="prama-button full-button" (click)="changeStatus('active');" [disabled]="timelineValidation.categories.length === 0" *ngIf="timeline.status === 2">
          <mat-icon>check</mat-icon> VALIDATE
        </button>
        <button mat-flat-button type="button" class="prama-button-red full-button" (click)="changeStatus('disabled');" *ngIf="timeline.status === 1">
          <mat-icon>highlight_off</mat-icon> DISABLE
        </button>
        <button mat-flat-button type="button" class="prama-button full-button" (click)="changeStatus('active');" [disabled]="timelineValidation.categories.length === 0" *ngIf="timeline.status === 4">
          <mat-icon>done</mat-icon> ENABLE
        </button>
        <button mat-flat-button type="button" class="prama-button full-button" (click)="changeStatus('active');" [disabled]="timelineValidation.categories.length === 0" *ngIf="timeline.status === 1">
          <mat-icon>arrow_circle_up</mat-icon> UPDATE
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div class="pending-timeline-data" fxFlexFill fxLayoutGap="20px" fxLayout="column" fxFlexAlign="stretch">

    <ng-container *ngIf="session !== undefined && session !== null && session.id !== 0">
      <h5 fxFlex="nogrow" class="mt-4">SESSION CONFIGURATION</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
          <div fxLayoutGap="10px grid" fxLayout="row wrap" fxFlex="50">
            <ng-container *ngFor="let exercise of this.sessionExercises">
              <ng-container *ngIf="exercise.showOrder>=0">
                <div fxFlex="25" class="preview-exercise">
                  <div class="preview-exercise-interior">
                    <img fxFlexFill [src]="exercisesMediaURL + exercise.exercise + '.gif'">
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div fxLayoutGap="10px" fxLayout="column" fxFlex="50" class="mt-3 mb-3">
            <div fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between start">
              <div class="session-big-number">
                <h6>TOTAL TIME</h6>
                <div class="big-number">{{session.totalTime}}</div>
              </div>
              <div class="session-big-number">
                <h6>ROUNDS</h6>
                <div class="big-number">{{timeline.session.rounds.length}}</div>
              </div>
              <div class="session-big-number">
                <h6>WORK TIME</h6>
                <div class="big-number">{{timeline.session.rounds[0].work}}"</div>
              </div>
              <div class="session-big-number">
                <h6>REST TIME</h6>
                <div class="big-number">{{timeline.session.rounds[0].rest}}"</div>
              </div>
              <div class="session-big-number">
                <h6>TIME BETWEEN ROUNDS</h6>
                <div class="big-number">{{timeline.session.rounds[0].extraRest | timeToMinutes}}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-container>

    <ng-container *ngIf="finisher !== undefined && finisher !== null && finisher.id !== 0">
      <h5 fxFlex="nogrow" class="mt-4">FINISHER CONFIGURATION</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
          <div fxLayoutGap="10px grid" fxLayout="row wrap" fxFlex="50">
            <div fxFlex="25" class="preview-exercise" *ngFor="let exercise of this.finisherExercises">
              <div class="preview-exercise-interior">
                <img [src]="exercisesMediaURL + exercise.exercise + '.gif'">
              </div>
            </div>
          </div>
          <div fxLayoutGap="10px" fxLayout="column" fxFlex="50" class="mt-3 mb-3">
            <div fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between start">
              <div class="session-big-number">
                <h6>ROUNDS</h6>
                <div class="big-number">{{timeline.finisher.rounds.length}}</div>
              </div>
              <div class="session-big-number">
                <h6>WORK TIME</h6>
                <div class="big-number">{{timeline.finisher.rounds[0].work}}"</div>
              </div>
              <div class="session-big-number">
                <h6>REST TIME</h6>
                <div class="big-number">{{timeline.finisher.rounds[0].rest}}"</div>
              </div>
              <div class="session-big-number">
                <h6>TIME BETWEEN ROUNDS</h6>
                <div class="big-number">{{timeline.finisher.rounds[0].extraRest | timeToMinutes}}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-container>

    <ng-container *ngIf="timeline.cardioExercises !== undefined && timeline.cardioExercises !== null && timeline.cardioExercises.length > 0">
      <h5 fxFlex="nogrow" class="mt-4">CARDIO CONFIGURATION</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
          <div fxLayoutGap="10px grid" fxLayout="row wrap" fxFlex="50">
            <div fxFlex="25" class="preview-exercise" *ngFor="let exercise of this.cardioExercises">
              <div class="preview-exercise-interior">
                <img fxFlexFill [src]="exercisesMediaURL + exercise.exercise + '.gif'">
              </div>
            </div>
          </div>
          <div fxLayoutGap="10px" fxLayout="column" fxFlex="50" class="mt-3 mb-3">
            <div fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between start">
              <div class="session-big-number">
                <h6>CIRCUIT REPETITIONS</h6>
                <div class="big-number">{{timeline.circuitRepetitions}}</div>
              </div>
              <div class="session-big-number">
                <h6>TIME BETWEEN CIRCUITS</h6>
                <div class="big-number">{{timeline.timeBetweenCircuits}}"</div>
              </div>
              <div class="session-big-number">
                <h6>TIME BETWEEN FUNCTIONAL AND CARDIO ZONES</h6>
                <div class="big-number">{{timeline.timeBetweenFunctionalAndCardio}}"</div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
</form>
<!-- <pre>
  {{timeline | json}}
</pre> -->