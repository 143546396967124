export const ENDPOINTS = {
  equipment: {
    get: "/creator/equipment",
    post: "/creator/equipment",
    put: "/creator/equipment",
    delete: "/creator/equipment/:id"
  },
  exercises: {
    get: "/creator/exercise",
  },
  exercisesDetail: {
    get: "/creator/exercise/detailed",
  },
  exercise: {
    get: "/creator/exercise/:id",
    post: "/creator/exercise",
    put: "/creator/exercise",
    delete: "/creator/exercise/:id"
  },
  exerciseEquipment: {
    get: "/creator/exercise/:id/equipment",
    post: "/creator/exercise/:id/equipment/:equipmentId",
  },
  exerciseTag: {
    get: "/creator/exercise/:id/tags",
    post: "/creator/exercise/:id/tags/:tagId",
  },
  exerciseProgram: {
    get: "/creator/exercise/:id/programs",
    post: "/creator/exercise/:id/programs/:programId",
  },
  exerciseTypes: {
    get: "/creator/exercise/types",
  },
  exerciseSkills: {
    get: "/creator/skills",
  },
  levels: {
    get: "/creator/level",
    post: "/creator/level",
    put: "/creator/level",
    delete: "/creator/level/:id"
  },
  intensityLevels: {
    get: "/creator/intensitylevels",
  },
  complexityLevels: {
    get: "/creator/complexitylevels",
  },
  muscularGroups: {
    get: "/creator/musculargroups"

  },
  fundamentalMovements: {
    get: "/creator/fundamentalmovements"
  },
  disciplines: {
    get: "/creator/disciplines"
  },
  media: {
    get: "/media",
    post: "/media/upload",
  },
  moods: {
    get: "/creator/mood",
  },
  musicIntervals: {
    get: "/creator/Song/interval",
  },
  musicPhases: {
    get: "/creator/Song/musicphase"
  }, 
  musicPackages:{
    get: "/creator/song/package",
  },
  musicPackagePhase: {
    put: "/creator/song/package/phase"
  },
  musicPackageInterval: {
    post: "/creator/song/packageinterval",
    delete: "/creator/song/packageinterval"
  },
  musicProgramPackages: {
    get: "/creator/song/program/:programId/packages",
    post: "/creator/Song/package",
    put: "/creator/Song/package",
    delete: "/creator/song/package/:packageId"
  },
  musicProviders: {
    get: "/creator/Song/musicprovider",
    post: "/creator/Song/musicprovider",
    put: "/creator/Song/musicprovider",
    delete: "/creator/Song/musicprovider"
  },
  playlists: {
    get: "/creator/program/:id/playlist",
  },
  packagesManagement: {
    get: "/creator/program/all",
    post: "/creator/program",
    put: "/creator/program"
  },
  gif: {
    get: "/media/convert/:filename",
    post: "/media/convert/:filename",
  },
  programs: {
    get: "/creator/program",
    post: "/creator/program",
    put: "/creator/program",
    delete: "/creator/program/:id"
  },
  programsAmbience: {
    get: "/creator/program/ambiences",
    post: "/creator/program/ambience",
    put: "/creator/program/ambience"
  },
  programsAmbiencePhases: {
    put: "/creator/program/ambiencephases"
  },
  oldPrograms: {
    get: "/creator/program/old"
  },
  programsByType: {
    get: "/creator/program/bytype"
  },
  programTypes: {
    get: "/creator/program/types"
  },
  sequenceColorModes: {
    get: "/creator/sequence/colormode",
  },
  sequenceCombo: {
    get: "/creator/sequence/combo/:id",
    post: "/creator/sequence/combo",
    put: "/creator/sequence/combo",
  },
  sequenceSprint: {
    get: "/creator/sequence/sprint/:id",
    post: "/creator/sequence/sprint",
    put: "/creator/sequence/sprint",
  },
  sequenceComboBlocks: {
    get: "",
    post: "",
    put: "",
    delete: "",
  },
  sequenceResponseTypes: {
    get: "/creator/sequence/response",
  },
  sequenceTypes: {
    get: "/creator/sequence/types",
  },
  songsRead: {
    post: "/creator/song/readmusic",
  }, 
  songsUpload: {
    post: "/creator/song",
  },
  songs:{
    get: "/creator/song/allsongs",
    post: "/creator/song/new",
    put: "/creator/song",
    delete: "/creator/song/delete/:id"
  },
  stations: {
    get: "/creator/station",
    post: "/creator/station",
    put: "/creator/station",
    delete: "/creator/station/:id"
  },
  stationTypes: {
    get: "/creator/station/types",
    post: "/creator/station/types",
    put: "/creator/station/types",
    delete: "/creator/station/types/:id"
  },
  status: {
    get: "/creator/status",
    post: "/creator/status",
    put: "/creator/status",
    delete: "/creator/status/:id"
  },
  tags: {
    get: "/creator/tag",
    post: "/creator/tag",
    put: "/creator/tag",
    delete: "/creator/tag/:id"
  },
  token: {
    post: "/token/user",
  },
  triggers:{
    get: "/creator/trigger",
    post: "/creator/trigger",
    put: "/creator/trigger",
    delete: "/creator/trigger/:id",
    audioGet: "/creator/triggeraudio",
    audioPost: "/creator/triggeraudio",
    audioPut: "/creator/triggeraudio",
    audioDelete: "/creator/triggeraudio/:id",
    moodGet: "/creator/triggermood",
    moodPost: "/creator/triggermood",
    moodPut: "/creator/triggermood",
    moodDelete: "/creator/triggermood/:id"

  },
  refreshToken: {
    post: "/token/user/renew",
  },
  applications: {
    get: "/creator/getapplications"
  },
  workoutMode: {
    get: "/creator/WorkoutMode",
    post: "/creator/WorkoutMode",
    put: "/creator/WorkoutMode",
    delete: "/creator/WorkoutMode/:id"
  },
  sessions: {
    get: "/creator/session/presets",
  },
  session: {
    get: "/creator/session/:id",
    post: "/creator/session",
    put: "/creator/session",
    delete: "/creator/session/:id"
  },
  executionModes: {
    get: "/creator/executionmode"
  },
  timelines: {
    get: "/creator/timeline",
    getByProgram: "/creator/Program/exercisetls/:id",
    putProgram: "/creator/Program/exercisetls",
    getBasic: "/creator/Program/basic",
    putBasic: "/creator/Program/basic",
  },
  timeline: {
    get: "/creator/timeline/:id",
    post: "/creator/timeline",
    put: "/creator/timeline",
  },
  timelineCountdowns: {
    get: "/creator/timelinecountdown"
  },
  timelineWelcomes: {
    get: "/creator/timelinewelcome"
  },
  timelineExplanations: {
    get: "/creator/timelineexplanation"
  },
  timelineWarmUps: {
    get: "/creator/timelinewarmup"
  },
  timelinePositionings: {
    get: "/creator/timelinepositioning"
  },
  timelineResults: {
    get: "/creator/timelineresult"
  },
  timelineCooldowns: {
    get: "/creator/timelinecooldown"
  },
  timelineEndings: {
    get: "/creator/timelineending"
  },
  timelineFinishers: {
    get: "/creator/timelinefinisher"
  },
  validateToken: {
    post: "/token/user/valid"
  },
  homeFitnessSessions: {
    get: "/creator/session/homefitness",  
  },
  homeFitnessSession: {
    get: "",  
    post: "",
    put: "",
    delete: "/creator/session/disable/:id"
  }, 
  homeFitnessWorkouts: {
    get: "/creator/homefitness/workouts"
  },
  homeFitnessWorkout: {
    get: "/creator/homefitness/workout-lang/:id",  
    post: "/creator/homefitness/workout-lang",
    put: "/creator/homefitness/workout-lang",
    delete: "/creator/homefitness/disable/:id"
  },
  blockEditor:{
    get: "/blocks/homefitness/workout/:id",
    post: "/blocks/homefitness/workout",
    put: "/blocks/homefitness/workout"
  },
  categories: {
    get: "/blocks/category",
    post:"/blocks/category",
    put: "/blocks/category",
    delete: "/blocks/category/:id"
  },
  workouts: {
    get: "/blocks/homefitness/workout/workouts-by-chain",
  },
  timelineValidation:{
    getPending: "/creator/timeline/validation",
    postValidation: "/creator/timeline/validation/response",
    getTimelineLogs: "/creator/timeline/log/:id"
  },
  sharedContent:{
    get: "/blocks/sharedcontent/workout/:id",    
    put: "/blocks/sharedcontent/workout"
  },
/*
  homeFitnessWorkoutOld: {
    get: "/creator/homefitness/workout/:id",  
    post: "/creator/homefitness/workout",
    put: "/creator/homefitness/workout",
    delete: "/creator/homefitness/disable/:id"
  },
*/
  languages: {
    get: "/common/langs"
  },
  thumbnail: {
    get: "/media/thumbnails/:filename"
  }

};
