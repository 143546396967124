import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";
import { ITrigger } from '../interfaces/models/i-trigger';
import { ITriggerAudio } from '../interfaces/models/i-trigger-audio';
import { ITriggerMood } from '../interfaces/models/i-trigger-mood';
import { of } from 'rxjs';


@Injectable()
export class TriggersService {

    private urlGetTriggers: string;
    private urlPostTrigger: string;
    private urlPutTrigger: string;

    private urlGetAudios: string;
    private urlPostAudio: string;
    private urlPutAudio: string;

    private urlGetMoods: string;
    private urlPostMood: string;
    private urlPutMood: string;

    constructor(public http: HttpClient) {
        this.urlGetTriggers = CONFIG.serverUrl + ENDPOINTS.triggers.get;
        this.urlPostTrigger = CONFIG.serverUrl + ENDPOINTS.triggers.post;
        this.urlPutTrigger = CONFIG.serverUrl + ENDPOINTS.triggers.put;

        this.urlGetAudios = CONFIG.serverUrl + ENDPOINTS.triggers.audioGet;
        this.urlPostAudio = CONFIG.serverUrl + ENDPOINTS.triggers.audioPost;
        this.urlPutAudio = CONFIG.serverUrl + ENDPOINTS.triggers.audioPut;

        this.urlGetMoods = CONFIG.serverUrl + ENDPOINTS.triggers.moodGet;
        this.urlPostMood = CONFIG.serverUrl + ENDPOINTS.triggers.moodPost;
        this.urlPutMood = CONFIG.serverUrl + ENDPOINTS.triggers.moodPut;
    }

    public getTriggers(): Observable<any> {
        return this.http.get(this.urlGetTriggers);
    }
    public putTrigger(trigger: ITrigger): Observable<any> {
        const data = trigger;
        return this.http.put(this.urlPutTrigger, data);
    }
    public postTrigger(trigger: ITrigger): Observable<any> {
        const data = trigger;
        return this.http.post(this.urlPostTrigger, data);
    }
    public getTriggerTypes(): Observable<any> {
        return of(
            [
                { id: 1, name: 'Last Chance', triggers: [3], checked: false },
                { id: 2, name: 'Last 10 seconds workout', triggers: [4], checked: false },
                { id: 3, name: '70% HR', triggers: [13], checked: false },
                { id: 4, name: '80% HR', triggers: [5], checked: false },
                { id: 5, name: '90% HR', triggers: [6], checked: false },
                { id: 7, name: '90% HR MyZone', triggers: [17], checked: false },
                { id: 6, name: 'Workout Guide', triggers: [1, 2, 7, 10, 12], checked: false }
            ]
        );
    }

    public getHomeTriggerTypes(): Observable<any> {
        return of(
            [
                { id: 1, name: 'Phase countdown 5s', triggers: [45], checked: false },
                { id: 2, name: 'Phase countdown 3s', triggers: [46], checked: false },
            ]
        );
    }

    public getAudios(): Observable<any> {
        return this.http.get(this.urlGetAudios);
    }
    public putAudio(audio: ITriggerAudio): Observable<any> {
        const data = audio;
        return this.http.put(this.urlPutAudio, data);
    }
    public postAudio(audio: ITriggerAudio): Observable<any> {
        const data = audio;
        return this.http.post(this.urlPostAudio, data);
    }

    public getMoods(): Observable<any> {
        return this.http.get(this.urlGetMoods);
    }
    public putMood(mood: ITriggerMood): Observable<any> {
        const data = mood;
        return this.http.put(this.urlPutMood, data);
    }
    public postMood(mood: ITriggerMood): Observable<any> {
        const data = mood;
        return this.http.post(this.urlPostMood, data);
    }
}
