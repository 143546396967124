// ANGULAR
import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

// THIRD PARTY
// import { Ng2CompleterModule } from "ng2-completer";
import { ColorPickerModule } from "ngx-color-picker";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ResizableModule } from 'angular-resizable-element';
import { DragAndDropModule } from 'angular-draggable-droppable';

// COMPONENTS
import { AppComponent } from "./components/app/app.component";
import { ExerciseListComponent } from "./components/exercise-list/exercise-list.component";
import { ExerciseListItemLevelComponent } from "./components/exercise-listitem-level/exercise-listitem-level.component";
import { ExerciseDetailComponent } from "./components/exercise-detail/exercise-detail.component";
import { ExerciseFilterComponent } from "./components/exercise-filter/exercise-filter.component";
// import { TagsPanelComponent } from "./components/tags-panel/tags-panel.component";
import { TagBadgeComponent } from "./components/tag-badge/tag-badge.component";
import { VideoUploaderComponent } from "./components/video-uploader/video-uploader.component";
import { ImageUploaderComponent } from "./components/image-uploader/image-uploader.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { ErrorComponent } from "./components/error/error.component";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { SessionFormComponent } from "./components/session-form/session-form.component";
import { SessionsListComponent } from "./components/sessions-list/sessions-list.component";
import { ProgramsListComponent } from "./components/programs-list/programs-list.component";
import { ProgramsBasicListComponent, DisableExerciseModal, EnableTimelineModal } from "./components/programs-basic-list/programs-basic-list.component";
import { TimelineFormComponent } from "./components/timeline-form/timeline-form.component";
import { TimelinesListComponent } from "./components/timelines-list/timelines-list.component";
import { PendingTimelinesListComponent } from "./components/pending-timelines-list/pending-timelines-list.component";
import { PendingTimelineFormComponent } from "./components/pending-timeline-form/pending-timeline-form.component";
import { SequencesListComponent } from "./components/sequences-list/sequences-list.component";
import { SequencePlayerComponent } from "./components/sequence-player/sequence-player.component";
import { SequenceTileComponent } from "./components/sequence-tile/sequence-tile.component";
import { SequenceColorPickerComponent } from "./components/sequence-color-picker/sequence-color-picker.component";
import { SequenceComboComponent } from "./components/sequence-combo/sequence-combo.component";
import { ExerciseSearchComponent } from "./components/exercise-search/exercise-search.component";
import { ComboSequenceConfiguratorComponent } from "./components/combo-sequence-configurator/combo-sequence-configurator.component";
import { SprintSequenceConfiguratorComponent } from "./components/sprint-sequence-configurator/sprint-sequence-configurator.component";
import { SessionFilterComponent } from "./components/session-filter/session-filter.component";
import { SessionModesComponent } from "./components/session-modes/session-modes.component";
import { HeaderComponent } from "./components/common/header/header.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './components/dynamic-form/dynamic-form-question.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { SessionTriggerComponent } from './components/session-trigger/session-trigger.component';
import { MusicManagerProgramComponent } from './components/music-manager-program/music-manager-program.component';
import { MusicManagerPhaseComponent } from './components/music-manager-phase/music-manager-phase.component';
import { MusicUploaderComponent } from './components/music-uploader/music-uploader.component';
import { MusicManagerSongsComponent } from './components/music-manager-songs/music-manager-songs.component';
import { MusicManagerHomeComponent } from './components/music-manager-home/music-manager-home.component';
import { MusicManagerProvidersComponent } from './components/music-manager-providers/music-manager-providers.component';
import { ModalRemoveProviderComponent } from './components/music-manager-providers/modal-remove-provider.component';
import { MusicManagerSongsFormComponent } from './components/music-manager-songs-form/music-manager-songs-form.component';
import { FinishersListComponent } from "./components/finisher-list/finishers-list.component";
import { FinisherFormComponent } from "./components/finisher-form/finisher-form.component";
import { SongsFilterComponent } from './components/songs-filter/songs-filter.component';
import { MusicManagerSchedulerComponent } from './components/music-manager-scheduler/music-manager-scheduler.component';
import { ModalRemoveSongComponent } from "./components/music-manager-songs/modal-remove-song.component";
import { MusicManagerPackagesComponent } from './components/music-manager-packages/music-manager-packages.component';
import { ProgramsPackagesListComponent } from './components/programs-packages-list/programs-packages-list.component';
import { SongSearchComponent } from './components/song-search/song-search.component';
import { ModalRemoveMusicPackageComponent } from "./components/music-manager-packages/modal-remove-music-package.component";
import { ModalRemoveProgramPackageComponent } from "./components/programs-packages-list/modal-remove-program-package.component";
import { ProgramFormComponent } from './components/program-form/program-form.component';

import { HomeFitnessComponent } from './components/home-fitness/home-fitness.component';
import { HomeFitnessSessionListComponent } from './components/home-fitness-session-list/home-fitness-session-list.component';
import { HomeFitnessSessionFormComponent } from './components/home-fitness-session-form/home-fitness-session-form.component';
import { HomeFitnessWorkoutListComponent } from './components/home-fitness-workout-list/home-fitness-workout-list.component';
import { HomeFitnessWorkoutFormComponent } from './components/home-fitness-workout-form/home-fitness-workout-form.component';
import { HomeFitnessStudioWorkoutListComponent } from './components/home-fitness-studio-workout-list/home-fitness-studio-workout-list.component';
import { HomeFitnessWorkshopListComponent } from './components/home-fitness-workshop-list/home-fitness-workshop-list.component';
import { HomeFitnessChallengeListComponent } from './components/home-fitness-challenge-list/home-fitness-challenge-list.component';
import { HomeFitnessContentManagerComponent } from './components/home-fitness-content-manager/home-fitness-content-manager.component';
import { ModalHomeFitnessRemoveSession } from "./components/home-fitness-session-list/modal-home-fitness-remove-session.component";
import { ModalHomeFitnessRemoveWorkout } from "./components/home-fitness-workout-list/modal-home-fitness-remove-workout.component";
import { BlockEditorComponent } from './components/block-creator/block-editor/block-editor.component';
import { WorkoutFormComponent } from './components/block-creator/workout-form/workout-form.component';
import { DialogEditBlockComponent } from './components/block-creator/block-editor/dialog-edit-block/dialog-edit-block.component';
import { CategoriesManagerComponent } from './components/categories-manager/categories-manager.component';
import { ModalRemoveCategoryComponent } from "./components/categories-manager/modal-remove-category.component";
import { ExerciseFormComponent } from "./components/exercise-form/exercise-form.component";
import { WorkoutListComponent } from "./components/workout-list/workout-list.component";

// PIPES
import { TimeToMinutesPipe } from "./pipes/time-to-minutes.pipe";

// SERVICES
import { ExerciseService } from "./services/exercise.service";
import { TagsService } from "./services/tags.service";
import { ProgramService } from "./services/program.service";
import { StationService } from "./services/station.service";
import { EquipmentService } from "./services/equipment.service";
import { LevelService } from "./services/level.service";
import { StatusService } from "./services/status.service";
import { NotificationService } from "./services/notification.service";
import { ComboSequenceService } from "./services/combo-sequence.service";
import { SprintSequenceService } from "./services/sprint-sequence.service";
import { DataService } from "./services/data.service";
import { SessionService } from "./services/session.service";
import { TimelineService } from "./services/timeline.service";
import { WorkoutModeService } from "./services/workoutmode.service";
import { MoodService } from "./services/mood.service";
import { TriggersService } from "./services/triggers.service";
import { AuthService } from "./services/auth.service";
import { UploaderService } from "./services/uploader-service";
import { PlaylistsService } from "./services/playlists.service";
import { LoadingService } from "./services/loading.service";
import { SongsService } from "./services/song-service";
import { MusicService } from "./services/music-service";
import { HomeFitnessSessionService } from "./services/home-fitness-session.service";
import { HomeFitnessWorkoutService } from "./services/home-fitness-workout.service";
import { LanguageService } from "./services/language.service";
import { BlockSessionService } from "./services/blocksession.service";
import { BlockEditorService } from "./services/block-editor.service";
import { WorkoutService } from "./services/workout.service";
import { EventsService } from "./services/events.service";

// RESOLVERS
import { ExerciseDetailResolver } from "./services/resolvers/exercise-detail-resolver";
import { SessionFormResolver } from "./services/resolvers/session-form-resolver";
import { ComboSequenceConfiguratorResolver } from "./services/resolvers/combo-sequence-configurator-resolver";
import { SprintSequenceConfiguratorResolver } from "./services/resolvers/sprint-sequence-configurator-resolver";

import { MoodsAvailableResolver } from './services/resolvers/moods-available-resolver';
import { PlaylistsAvailableResolver } from './services/resolvers/playlists-available-resolver';
import { WorkoutModesAvailableResolver } from './services/resolvers/workoutmodes-available-resolver';
import { ProgramsAvailableResolver } from './services/resolvers/programs-available-resolver';
import { OldProgramsAvailableResolver } from './services/resolvers/old-programs-available-resolver';
import { ExercisesResolver } from './services/resolvers/exercises-resolver';
import { ExerciseResolver } from "./services/resolvers/exercise-resolver";
import { StationsResolver } from './services/resolvers/stations-resolver';
import { SessionsResolver } from './services/resolvers/sessions-resolver';
import { TimelinesResolver } from './services/resolvers/timelines-resolver';
import { TimelineCountdownsResolver } from './services/resolvers/timeline-countdowns-resolver';
import { TimelineWelcomesResolver } from './services/resolvers/timeline-welcomes-resolver';
import { TimelineExplanationsResolver } from './services/resolvers/timeline-explanations-resolver';
import { TimelineWarmUpsResolver } from './services/resolvers/timeline-warmups-resolver';
import { TimelinePositioningsResolver } from './services/resolvers/timeline-positionings-resolver';
import { TimelineResultsResolver } from './services/resolvers/timeline-results-resolver';
import { TimelineCooldownsResolver } from './services/resolvers/timeline-cooldowns-resolver';
import { TimelineEndingsResolver } from './services/resolvers/timeline-endings-resolver';
import { TimelineSessionsResolver } from './services/resolvers/timeline-sessions-resolver';
import { TimelineFinishersResolver } from './services/resolvers/timeline-finishers-resolver';
import { TimelineFormResolver } from './services/resolvers/timeline-form-resolver';
import { PendingTimelinesResolver } from './services/resolvers/pending-timelines-resolver';
import { TimelineLogResolver } from './services/resolvers/timeline-log-resolver';
import { TagsResolver } from './services/resolvers/tags-resolver';
import { SkillsResolver } from './services/resolvers/skills-resolver';
import { IntensityLevelsResolver } from './services/resolvers/intensitylevels-resolver';
import { ComplexityLevelsResolver } from './services/resolvers/complexitylevels-resolver';
import { MuscularGroupsResolver } from './services/resolvers/musculargroups-resolver';
import { FundamentalMovementsResolver } from './services/resolvers/fundamentalmovements-resolver';
import { DisciplinesResolver } from './services/resolvers/disciplines-resolver';
import { EquipmentResolver } from './services/resolvers/equipment-resolver';
import { TriggersResolver } from './services/resolvers/triggers-resolver';
import { TriggerTypesResolver } from './services/resolvers/triggers-resolver';
import { HomeTriggerTypesResolver } from './services/resolvers/triggers-resolver';
import { TriggerAudiosResolver } from './services/resolvers/triggers-resolver';
import { TriggerMoodsResolver } from './services/resolvers/triggers-resolver';
import { MusicProgramResolver } from "./services/resolvers/music-program-resolver";
import { PlaylistSongsResolver } from "./services/resolvers/playlist-songs-resolver";
import { MusicProviderResolver } from "./services/resolvers/music-providers-resolver";
import { SongsListResolver } from "./services/resolvers/songs-list-resolver";
import { MusicPhasesResolver } from "./services/resolvers/music-phases-resolver";
import { MusicProgramPackagesResolver } from "./services/resolvers/music-program-packages-resolver";
import { PackagesManagementResolver } from "./services/resolvers/packages-management-resolver";
import { MusicIntervalsResolver } from "./services/resolvers/music-intervals-resolver";
import { MusicPackagesResolver } from "./services/resolvers/music-packages-resolver";
import { ProgramsByTypeResolver } from "./services/resolvers/programs-by-type-resolver";
import { ProgramAmbiencePhasesResolver } from "./services/resolvers/programs-ambience-phases-resolver";
import { ExecutionModesResolver } from "./services/resolvers/execution-modes-resolver";
import { HomeFitnessSessionsResolver } from "./services/resolvers/home-fitness-sessions-resolver.service";
import { HomeFitnessWorkoutsResolver } from "./services/resolvers/home-fitness-workouts-resolver.service";
import { HomeFitnessWorkoutResolver } from "./services/resolvers/home-fitness-workout-resolver.service";
import { LanguagesResolver } from "./services/resolvers/languages-resolver";
import { BlockEditorResolver } from "./services/resolvers/block-editor-resolver";
import { CategoriesService } from "./services/categories.service";
import { CategoriesResolver } from "./services/resolvers/categories-resolver";
import { SharedContentResolver } from "./services/resolvers/shared-content-resolver";
import { WorkoutsResolver } from "./services/resolvers/workouts-resolver";

// INTERCEPTORS
import { AuthInterceptor } from "./services/interceptors/auth-interceptor";
import { LogInterceptor } from "./services/interceptors/log-interceptor";

// GUARDS
import { AuthGuard } from "./services/guards/auth-guard";

// MAPPERS
//import { ExerciseMapper } from "./services/mappers/exercise-mapper";
import { SequenceMapper } from "./services/mappers/sequence-mapper";
//import { SessionMapper } from "./services/mappers/session-mapper";
import { SprintSequenceMapper } from "./services/mappers/sprint-sequence-mapper";

// APP
import { CONFIG } from "../assets/config";
import { ENUMS } from "../assets/enums";
import { AppRoutingModule } from "./app-routing.module";

// MATERIAL
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EditorBlockDirective } from './directives/editor-block.directive';
import { SharedContentComponent } from './components/block-creator/shared-content/shared-content.component';




@NgModule({
  declarations: [
    AppComponent,
    ExerciseListComponent,
    ExerciseListItemLevelComponent,
    ExerciseDetailComponent,
    ExerciseFilterComponent,
    // TagsPanelComponent,
    TagBadgeComponent,
    VideoUploaderComponent,
    ImageUploaderComponent,
    SpinnerComponent,
    LoginComponent,
    ErrorComponent,
    HomeComponent,
    SessionFormComponent,
    ComboSequenceConfiguratorComponent,
    TimelinesListComponent,
    TimelineFormComponent,
    PendingTimelinesListComponent,
    PendingTimelineFormComponent,
    SessionsListComponent,
    ProgramsListComponent,
    ProgramsBasicListComponent,
    SequencesListComponent,
    SequencePlayerComponent,
    SequenceColorPickerComponent,
    SequenceTileComponent,
    SequenceComboComponent,
    ExerciseSearchComponent,
    SessionFilterComponent,
    SprintSequenceConfiguratorComponent,
    TimeToMinutesPipe,
    SessionModesComponent,
    HeaderComponent,
    FooterComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    LoadingComponent,
    SessionTriggerComponent,
    MusicManagerProgramComponent,
    MusicManagerPhaseComponent,
    MusicUploaderComponent,
    FinishersListComponent,
    FinisherFormComponent,
    DisableExerciseModal,
    EnableTimelineModal,
    MusicManagerSongsComponent,
    MusicManagerHomeComponent,
    MusicManagerProvidersComponent,
    ModalRemoveProviderComponent,
    MusicManagerSongsFormComponent,
    SongsFilterComponent,
    MusicManagerSchedulerComponent,
    ModalRemoveSongComponent,
    MusicManagerPackagesComponent,
    ProgramsPackagesListComponent,
    SongSearchComponent,
    ModalRemoveMusicPackageComponent,
    ModalRemoveProgramPackageComponent,
    ProgramFormComponent,
    HomeFitnessComponent,
    HomeFitnessSessionListComponent,
    HomeFitnessSessionFormComponent,
    HomeFitnessWorkoutListComponent,
    HomeFitnessWorkoutFormComponent,
    HomeFitnessStudioWorkoutListComponent,
    HomeFitnessWorkshopListComponent,
    HomeFitnessChallengeListComponent,
    HomeFitnessContentManagerComponent,
    ModalHomeFitnessRemoveSession,
    ModalHomeFitnessRemoveWorkout,
    BlockEditorComponent,
    EditorBlockDirective,
    WorkoutFormComponent,
    DialogEditBlockComponent,
    CategoriesManagerComponent,
    ModalRemoveCategoryComponent,
    ExerciseFormComponent,
    WorkoutListComponent,
    SharedContentComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    // Ng2CompleterModule,
    ColorPickerModule,
    AppRoutingModule,
    InfiniteScrollModule,
    ResizableModule,
    DragAndDropModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatGridListModule,
    MatTableModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatSliderModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatTreeModule,
    DragDropModule,
    ModalModule.forRoot()
  ],
  providers: [
    Title,
    // GUARDS
    AuthGuard,
    // SERVICES
    AuthService,
    ExerciseService,
    TagsService,
    EquipmentService,
    ProgramService,
    StationService,
    LevelService,
    StatusService,
    NotificationService,
    ComboSequenceService,
    SprintSequenceService,
    DataService,
    SessionService,
    TimelineService,
    WorkoutModeService,
    MoodService,
    PlaylistsService,
    LoadingService,
    TriggersService,
    UploaderService,
    SongsService,
    MusicService,
    HomeFitnessSessionService,
    HomeFitnessWorkoutService,
    LanguageService,
    BlockSessionService,
    BlockEditorService,
    CategoriesService,
    WorkoutService,
    EventsService,

    //MAPPERS
    SequenceMapper,
    SprintSequenceMapper,

    // RESOLVERS
    ExerciseDetailResolver,
    ExerciseResolver,
    ComboSequenceConfiguratorResolver,
    SprintSequenceConfiguratorResolver,
    SessionFormResolver,
    MoodsAvailableResolver,
    PlaylistsAvailableResolver,
    WorkoutModesAvailableResolver,
    ProgramsAvailableResolver,
    OldProgramsAvailableResolver,
    ExercisesResolver,
    StationsResolver,
    SessionsResolver,
    TimelineFormResolver,
    TimelinesResolver,
    TimelineCountdownsResolver,
    TimelineWelcomesResolver,
    TimelineExplanationsResolver,
    TimelineWarmUpsResolver,
    TimelinePositioningsResolver,
    TimelineResultsResolver,
    TimelineCooldownsResolver,
    TimelineEndingsResolver,
    TimelineFinishersResolver,
    TimelineSessionsResolver,
    PendingTimelinesResolver,
    TimelineLogResolver,
    TagsResolver,
    SkillsResolver,
    IntensityLevelsResolver,
    ComplexityLevelsResolver,
    MuscularGroupsResolver,
    FundamentalMovementsResolver,
    DisciplinesResolver,
    EquipmentResolver,
    TriggersResolver,
    TriggerTypesResolver,
    TriggerAudiosResolver,
    TriggerMoodsResolver,
    MusicProgramResolver,
    PlaylistSongsResolver,
    MusicProviderResolver,
    SongsListResolver,
    MusicPhasesResolver,
    MusicProgramPackagesResolver,
    PackagesManagementResolver,
    MusicIntervalsResolver,
    MusicPackagesResolver,
    ProgramsByTypeResolver,
    ProgramAmbiencePhasesResolver,
    ExecutionModesResolver,
    HomeFitnessSessionsResolver,
    HomeFitnessWorkoutsResolver,
    HomeFitnessWorkoutResolver,
    LanguagesResolver,
    HomeTriggerTypesResolver,
    BlockEditorResolver,
    CategoriesResolver,
    SharedContentResolver,
    WorkoutsResolver,

    // HTTP INTERCEPTORS
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },

    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
  entryComponents: [
    DisableExerciseModal,
    EnableTimelineModal,
    ModalRemoveProviderComponent,
    ModalRemoveSongComponent,
    ModalRemoveMusicPackageComponent,
    ModalRemoveProgramPackageComponent,
    ModalHomeFitnessRemoveSession,
    ModalHomeFitnessRemoveWorkout
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}

