<form (ngSubmit)="onSubmit()" #siteForm="ngForm">

  <h1>EXERCISE CREATOR</h1>

  <!-- EXERCISE INFO -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">MAIN INFO</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="end start">
          <h3 *ngIf="exercise.id !== 0" class="color-white">#{{exercise.id}}</h3>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="100">
            <!-- NAME -->
            <mat-form-field>
              <input matInput #exerciseName placeholder="Name" [value]="exercise.name" [(ngModel)]="exercise.name" name="exerciseName"
                required>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !exercise.name">
                Name is required
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="space-between stretch">

          <div fxLayout="column" fxFlex="25">
            <!-- STATION -->
            <mat-form-field>
              <mat-select #exerciseStation placeholder="Station" [compareWith]="compare" [(ngModel)]="exercise.station"
                name="exerciseStation" required>
                <mat-option *ngFor="let station of stationsAvailable" [value]="station">
                  {{station.name}}
                </mat-option>
              </mat-select>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !exercise.station">
                Station is required
              </mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="25">
            <!-- PROGRAM -->
            <mat-form-field>
              <mat-select #exerciseProgram placeholder="Program" [compareWith]="compare" [(ngModel)]="exercise.program"
                name="exerciseProgram">
                <mat-option *ngFor="let program of programsAvailable" [value]="program">
                  {{program.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="25">
            <!-- PACKAGES -->
            <mat-form-field>
              <mat-select #exercisePackages multiple placeholder="Packages" [compareWith]="compare" [(ngModel)]="packagesSelected"
                name="exercisePackages">
                <mat-option *ngFor="let program of packagesAvailable" [value]="program">
                  {{program.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="25">
            <!-- PLAYERS -->
            <mat-form-field>
              <mat-select #exercisePlayers placeholder="Players" [(value)]="exercise.players"
                name="exercisePlayers" >
                <mat-option [value]="1"> 1 </mat-option>
                <mat-option [value]="2"> 2 </mat-option>
                <mat-option [value]="3"> 3 </mat-option>
                <mat-option [value]="4"> 4 </mat-option>
              </mat-select>
             
            </mat-form-field>
          </div>

        </div>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch" class="posrel">

      <h5 fxFlex="nogrow">IMAGE</h5>
      <mat-card fxFlex class="nopadding">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill class="w-100">
          <!-- <image-uploader [imageName]="this.exercise.image" [mediaUrl]="this.exercise.id ? this.mediaUrl : this.tempMediaUrl" (selectedImageEvent)="imageSelected($event)"
            [formField]="" >
          </image-uploader> -->
          <img [src]="this.mediaUrl + this.exercise.image" alt="Exercise image" *ngIf="this.exercise.id && this.exercise.image">
          <img [src]="this.tempMediaUrl + this.exercise.image" alt="Exercise image" *ngIf="!this.exercise.id && this.exercise.image">
          <div class="w-100 text-center" fxLayout="row" fxLayoutAlign="center center" *ngIf="!this.exercise.image">
            <div>Image will be generated from uploaded video</div>
          </div>
        </div>
      </mat-card>

    </div>

  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <mat-toolbar class="mat-buttons-toolbar">
      <mat-toolbar-row>
        <span class="mat-spacer"></span>
        <button mat-flat-button type="submit" class="prama-button full-button medium-button">
          <mat-icon>save</mat-icon> SAVE
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="CONFIGURATION">

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="space-between stretch" fxFill>

        <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
          <h5 fxFlex="nogrow">BASIC</h5>
          <mat-card fxFlex>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
              <div fxLayout="column" fxFlex="50">
                <!-- INTENSITY LEVEL -->
                <mat-form-field>
                  <mat-select #exerciseIntensity placeholder="Intensity level" [compareWith]="compare" [(ngModel)]="exercise.intensityLevel"
                    name="exerciseIntensity" >
                    <mat-option *ngFor="let intensity of intensityLevelsAvailable" [value]="intensity">
                      {{intensity.name}}
                    </mat-option>
                  </mat-select>
                 
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <!-- COMPLEXITY LEVEL -->
                <mat-form-field>
                  <mat-select #exerciseComplexity placeholder="Complexity level" [compareWith]="compare" [(ngModel)]="exercise.complexityLevel"
                    name="exerciseComplexity" >
                    <mat-option *ngFor="let complexity of complexityLevelsAvailable" [value]="complexity">
                      {{complexity.name}}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
              <div fxLayout="column" fxFlex="50">
                <!-- SKILL -->
                <mat-form-field>
                  <mat-select #exerciseSkill placeholder="Skill" [compareWith]="compare" [(ngModel)]="exercise.skills"
                    name="exerciseSkill" >
                    <mat-option *ngFor="let skill of exerciseSkillsAvailable" [value]="skill">
                      {{skill.name}}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <!-- DISCIPLINE -->
                <mat-form-field>
                  <mat-select #exerciseDiscipline placeholder="Discipline" [compareWith]="compare" [(ngModel)]="exercise.discipline"
                    name="exerciseDiscipline" >
                    <mat-option *ngFor="let discipline of exerciseDisciplinesAvailable" [value]="discipline">
                      {{discipline.name}}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
              <div fxLayout="column" fxFlex="50">
                 <!-- COMPETITIVE -->
                <mat-form-field>
                  <mat-select #exerciseCompetitive placeholder="Is competitive" [value]="exercise.competitive"
                    name="exerciseSkill">
                    <mat-option [value]="true">
                      Competitivity
                    </mat-option>
                    <mat-option [value]="false">
                      No competitive
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
              
              </div>
            </div>
             
          </mat-card>

          <h5 fxFlex="nogrow">WEIGHTS</h5>
          <mat-card fxFlex>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
              <div fxLayout="column" fxFlex="50">
                <!-- MIN WEIGHT MALE -->
                <mat-form-field>
                  <input matInput #exerciseWeightMaleMin placeholder="Min weight (male)" [(ngModel)]="exercise.weightMaleMin"
                    name="exerciseWeightMaleMin" type="number" min="0" step=".5">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !exercise.weightMaleMin">
                    Field is required
                  </mat-hint> -->
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <!-- MAX WEIGHT MALE -->
                <mat-form-field>
                  <input matInput #exerciseWeightMaleMax placeholder="Max weight (male)" [(ngModel)]="exercise.weightMaleMax"
                    name="exerciseWeightMaleMax" type="number" min="0" step=".5">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !exercise.weightMaleMax">
                    Field is required
                  </mat-hint> -->
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
              <div fxLayout="column" fxFlex="50">
                <!-- MIN WEIGHT FEMALE -->
                <mat-form-field>
                  <input matInput #exerciseWeightFemaleMin placeholder="Min weight (female)" [(ngModel)]="exercise.weightFemaleMin"
                    name="exerciseWeightFemaleMin" type="number" min="0" step=".5">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !exercise.weightFemaleMin">
                    Field is required
                  </mat-hint> -->
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="50">
                <!-- MAX WEIGHT FEMALE -->
                <mat-form-field>
                  <input matInput #exerciseWeightFemaleMax placeholder="Max weight (female)" [(ngModel)]="exercise.weightFemaleMax"
                    name="exerciseWeightFemaleMax" type="number" min="0" step=".5">
                  <!-- <mat-hint class="form-error-message" *ngIf="formSubmitted && !exercise.weightFemaleMax">
                    Field is required
                  </mat-hint> -->
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>

        <div fxLayout="row" fxFlex="50" fxFlexAlign="stretch" fxLayoutGap="20px">

          <div fxLayout="column" fxFlex>
            <h5 fxFlex="nogrow">MUSCULAR GROUPS</h5>
            <mat-card fxFlex>
              <div class="exercise-scroll-list">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
                  <div fxLayout="column" fxFlex="100">
                    <!-- PRIMARY MUSCULAR GROUP -->
                    <h6 class="mt10 mb20">PRIMARY</h6>
                    <mat-form-field>
                      <mat-select #exerciseMuscularGroup1 placeholder="Primary muscular group" [compareWith]="compare"
                        [(ngModel)]="exercise.muscularGroup1" name="exerciseMuscularGroup1" >
                        <mat-option *ngFor="let muscularGroup of exerciseMuscularGroupsAvailable" [value]="muscularGroup">
                          {{muscularGroup.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
                  <div fxLayout="column" fxFlex="100">
                    <!-- SECONDARY MUSCULAR GROUPS -->
                    <h6 class="mt10 mb20">SECONDARY</h6>
                    <div class="group-selection" *ngFor="let muscularGroup of exerciseMuscularGroupsAvailable">
                      <mat-checkbox [value]="muscularGroup" [checked]="isChecked(exercise.muscularGroup2, muscularGroup)"
                        [disabled]="exercise.muscularGroup1 == muscularGroup" name="muscularGroup-{{muscularGroup.id}}"
                        (change)="changeItemFromList(exercise.muscularGroup2, muscularGroup, $event)">
                        {{ muscularGroup.name}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>

          <div fxLayout="column" fxFlex *ngIf="!exercise.program || exercise.program?.id === 1 || exercise.program?.id === 4">
            <h5 fxFlex="nogrow">FUNDAMENTAL MOVEMENTS</h5>
            <mat-card fxFlex>
              <div class="exercise-scroll-list">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
                  <div fxLayout="column" fxFlex="100">
                    <!-- PRIMARY FUNDAMENTAL MOVEMENT -->
                    <h6 class="mt10 mb20">PRIMARY</h6>
                    <mat-form-field>
                      <mat-select #exerciseFundamentalMovement1 placeholder="Primary fundamental movement"
                        [compareWith]="compare" [(ngModel)]="exercise.fundamentalMovement1" name="exerciseFundamentalMovement1">
                        <mat-option *ngFor="let fundamentalMovement of exerciseFundamentalMovementsAvailable" [value]="fundamentalMovement">
                          {{fundamentalMovement.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
                  <div fxLayout="column" fxFlex="100">
                    <!-- SECONDARY FUNDAMENTAL MOVEMENTS -->
                    <h6 class="mt10 mb20">SECONDARY</h6>
                    <div class="group-selection" *ngFor="let fundamentalMovement of exerciseFundamentalMovementsAvailable">
                      <mat-checkbox [value]="fundamentalMovement" [checked]="isChecked(exercise.fundamentalMovement2, fundamentalMovement)"
                        [disabled]="exercise.fundamentalMovement1 == fundamentalMovement" name="fundamentalMovement-{{fundamentalMovement.id}}"
                        (change)="changeItemFromList(exercise.fundamentalMovement2, fundamentalMovement, $event)">
                        {{ fundamentalMovement.name}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- EQUIPMENT -->
          <div fxLayout="column" fxFlex>
            <h5 fxFlex="nogrow">EQUIPMENT</h5>
            <mat-card fxFlex>
              <div class="exercise-scroll-list">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
                  <div fxLayout="column" fxFlex="100">
                    <div class="group-selection" *ngFor="let equip of equipmentAvailable">
                      <div fxLayout="row" fxLayoutAlign="space-between stretch">
                        <mat-checkbox [value]="equip" [checked]="isChecked(exercise.equipment, equip)" name="equip-{{equip.id}}"
                          (change)="changeItemFromList(exercise.equipment, equip, $event)">
                          {{ equip.name}}
                        </mat-checkbox>
                        <img class="equipment-img" [src]="'/assets/equipment/' + equip.id + '.png'" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="VIDEOS">

      <mat-card fxFlex class="nopadding">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill>
          <video-uploader [videoName]="this.exercise.video" [gifName]="this.exercise.thumbnail" (selectedVideoEvent)="videoSelected($event)"
            (selectedGifEvent)="gifSelected($event)" [editMode]="editMode">
          </video-uploader>
        </div>
      </mat-card>

    </mat-tab>
  </mat-tab-group>

</form>