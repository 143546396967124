const environment = ''; // dev / test / ''
const devEnvironment = 0;
const testEnvironment = 1;
const proEnvironment = 2;

//const serverURL: string = "http://localhost:58680";
const serverURL: string = 'https://' + environment + 'api.pramacloud.com';
//const serverURL: string =  'https://api-b.pramacloud.com';

const serverMediaURL: string = 'https://creator.pramacloud.com';
// QA
// const serverURL: string = 'http://qaapi.pramacloud.com';
// const serverMediaURL: string = 'http://qacreator.pramacloud.com';


const mediaURL: string = serverMediaURL + "/public";
const exercisesMediaURL: string = mediaURL + "/exercises/";
const sessionMediaURL: string = mediaURL + "/sessions/";
const workoutMediaURL: string = mediaURL + '/homefitness-workout/';
const tempMediaURL: string = mediaURL + "/temp/";
const programsMediaUrl: string = mediaURL + '/programs/';

export const CONFIG = { 
  appId: 1,
  version: "3.2.16",
  environment: testEnvironment,
  environments: {
    dev: devEnvironment,
    test: testEnvironment,
    pro: proEnvironment
  },
  serverUrl: serverURL,
  mediaUrl: mediaURL,
  exercisesMediaURL: exercisesMediaURL,
  sessionMediaURL: sessionMediaURL,
  workoutMediaURL: workoutMediaURL,
  programsMediaUrl: programsMediaUrl,
  tempMediaUrl: tempMediaURL,
  offline: false,
  traceHttp: false,
  traceLoads: true,
  authInterceptor: true,
  authGuard: true,
  
  supportedVideoFormats: [
    { mimeType: "video/mp4", extension: ".mp4" }
  ],
  supportedImageFormats: [
    { mimeType: "image/jpeg", extension: ".jpg" },
    { mimeType: "image/jpeg", extension: ".jpeg" },
    { mimeType: "image/png", extension: ".png" }
  ],
  sessionRules: {
    programEnergy: {
      noTwoConsecutiveHighIntensityExercises: true,
      noTwoConsecutiveLowIntensityExercises: true,
      noThreeConsecutiveExercisesWithSamePrimaryFM: true,
      noMore65PerCentExercisesWithSameFM: true,
      noMore40PerCentExercisesWithPrimaryFMType3: true,
      noMore50PerCentExercisesWithPrimaryFMType2: true,
      noMore40PerCentExercisesWithPrimaryFMType1: true,
      noMoreThanOneExerciseWithSameMaterial: true,
      atLeast25PerCentHighIntensityExercises: true,
      allFMTypesMustAppear: true,
      minOneSkillsExercise: true,

      noMoreThanThreeExercisesWithPrimaryFMType3: true,
      noMoreThanThreeExercisesWithPrimaryFMType2: true,
      noMoreThanThreeExercisesWithPrimaryFMType1: true,

      noMore5ExercisesWithSameFM: true,
      atLeast2HighIntensityExercises: true,
      type123PrimaryFMShouldAppear: true,
    },
    programStrength: {
      noThreeConsecutiveHighIntensityExercises: true,
      noTwoConsecutiveLowIntensityExercises: true,
      noThreeConsecutiveExercisesWithSamePrimaryGM: true,
      noMore25PerCentLowIntensityExercises: true,
      noMore75PerCentExercisesWithSameFM: true,
      noMore50PerCentExercisesWithSamePrimaryFM: true,
      atLeast25PerCentHighIntensityExercises: true,

      noMoreThanOneExerciseWithSameMaterial: true,
      noThreeConsecutiveExercisesWithSamePrimaryMG: true,
      noMoreThanFourExercisesWithSamePrimaryMG: true,
      noMoreThanSixExercisesWithSameMGPrimaryOrSecondary: true,
      moreThan60PerCentMGShouldAppear: true
    },
    programAthletic: {
      noThreeConsecutiveSameDisciplineExercises: true,
      noFourConsecutiveCardioSportExercises: true,
      noMore50PerCentExercisesSameDiscipline: true,
      atLeast25PerCentExercisesEachDiscipline: true,

      noMoreThanOneExerciseWithSameMaterial: true,
      neverZoneWithBothExercisesSameDiscipline: true,
      noMoreThanTwoStrengthExercisesWithSameFM: true,
      noMoreThanTwoStrengthExercisesWithSameMG: true
    },
    programFlow: {
      noMore50PerCentExercisesSameDiscipline: true,
      atLeast25PerCentExercisesEachDiscipline: true,

      noMoreThanOneExerciseWithSameMaterial: true,
      noThreeConsecutiveSameDisciplineExercises: true,
      noMoreThanFourExercisesSameDiscipline: true,
      atLeastTwoExercisesEachDiscipline: true,
    }
  }
};
