import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IExercise } from "../../interfaces/models/i-exercise";
import { ISession } from "../../interfaces/models/i-session";
import { ITimeline } from "../../interfaces/models/i-timeline";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { startWith, map } from "rxjs/operators";
import { ENUMS } from "../../../assets/enums";
import { AuthService } from "../../services/auth.service";

export interface IDataMini {
  id: number,
  name: string
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent {

  exercises: IExercise[];
  exerciseList: IDataMini[];
  filteredExercises: Observable<IDataMini[]>;
  exerciseCtrl = new FormControl();

  sessions: ISession[];
  sessionList: IDataMini[];
  filteredSessions: Observable<IDataMini[]>;
  sessionCtrl = new FormControl();

  timelines: ITimeline[];
  timelineList: IDataMini[];
  filteredTimelines: Observable<IDataMini[]>;
  timelineCtrl = new FormControl();

  pavigymHome: boolean;
  accessExercises: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
    this.exercises = this.route.snapshot.data.exercises;
    this.pavigymHome = authService.isInRole(ENUMS.roles.pavigym);
    this.accessExercises = authService.isInRole(ENUMS.roles.creator_exercise_list);

    this.exerciseList = [];
    if (this.exercises) {
      this.exercises.forEach(exercise => {
        let exerciseNew: IDataMini = {
          id: exercise.id,
          name: exercise.id + " - " + exercise.name
        };
        this.exerciseList.push(exerciseNew);
      });
    }

    this.filteredExercises = this.exerciseCtrl.valueChanges
      .pipe(
        startWith(''),
        map(exercise => exercise ? this._filterExercises(exercise) : this.exerciseList.slice())
      );

    this.sessions = this.route.snapshot.data.sessions;
    this.sessionList = [];
    if (this.sessions) {
      this.sessions.forEach(session => {
        let sessionNew: IDataMini = {
          id: session.id,
          name: session.id + " - " + session.name
        };
        this.sessionList.push(sessionNew);
      });
    }

    this.filteredSessions = this.sessionCtrl.valueChanges
      .pipe(
        startWith(''),
        map(session => session ? this._filterSessions(session) : this.sessionList.slice())
      );

    this.timelines = this.route.snapshot.data.timelines;
    this.timelineList = [];
    if (this.timelines) {
      this.timelines.forEach(timeline => {
        let timelineNew: IDataMini = {
          id: timeline.id,
          name: timeline.id + " - " + timeline.name
        };
        this.timelineList.push(timelineNew);
      });
    }

    this.filteredTimelines = this.timelineCtrl.valueChanges
      .pipe(
        startWith(''),
        map(timeline => timeline ? this._filterTimelines(timeline) : this.timelineList.slice())
      );
  }

  private _filterExercises(value: string): IDataMini[] {
    const filterValue = value.toLowerCase();

    return this.exerciseList.filter(exercise => exercise.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  public selectExercise($event) {
    this.router.navigate(['/exercise/' + $event.option.value]);
  }

  private _filterSessions(value: string): IDataMini[] {
    const filterValue = value.toLowerCase();

    return this.sessionList.filter(session => session.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  public selectSession($event) {
    this.router.navigate(['/session/' + $event.option.value]);
  }

  private _filterTimelines(value: string): IDataMini[] {
    const filterValue = value.toLowerCase();

    return this.timelineList.filter(timeline => timeline.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  public selectTimeline($event) {
    this.router.navigate(['/timeline/' + $event.option.value]);
  }


}
