import { BlockEditorComponent } from './../block-editor/block-editor.component';
import { ExerciseService } from './../../../services/exercise.service';
import { ViewChild } from '@angular/core';
import { IProgram } from './../../../interfaces/models/i-program';
import { Workout } from './../../../interfaces/models/blockworkout';
import { SharedContent } from './../../../interfaces/models/shared-content';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from './../../../services/language.service';
import { BlockEditorService } from './../../../services/block-editor.service';
import { NotificationService } from './../../../services/notification.service';
import { SharedContentService } from './../../../services/shared-content.service';

import { ENUMS } from "../../../../assets/enums";
import { CONFIG } from "../../../../assets/config";
import { Category } from './../../../interfaces/models/category';
import { CategoriesService } from './../../../services/categories.service';
import { AuthService } from './../../../services/auth.service';

@Component({
  selector: 'workout-form',
  templateUrl: './workout-form.component.html',
  styleUrls: ['./workout-form.component.scss']
})
export class WorkoutFormComponent implements OnInit {

  @ViewChild('siteForm') siteForm: any;

  @ViewChild(BlockEditorComponent) blockEditor: BlockEditorComponent;

  public workout: Workout;
  public programs: IProgram[];
  public sharedContent: SharedContent;

  public exercises = null;
  public categories = null;

  formSubmitted = false;
  isNew: Boolean = false;

  public saving: Boolean = false; // Se pondrá a true cuando esté en proceso de llamada a POST, false cuando reciba respuesta

  constructor(private route: ActivatedRoute,
    public languageService: LanguageService,
    public blockEditorService: BlockEditorService,
    public sharedContentService: SharedContentService,
    public notificationService: NotificationService,
    public categoriesService: CategoriesService,
    private router: Router,
    public authService: AuthService,
    private exerciseService: ExerciseService) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {

    // Default Workout para new Workout
    this.workout = {
      id: 0,
      name: {
        id: 0,
        key: 'TABLE_WORKOUT_NAME_95',
        resources: [
          {
            language: {
              id: 1,
              name: null,
              cultureInfo: null
            },
            value: ''
          },
          // {
          //   language: {
          //     id: 2,
          //     name: null,
          //     cultureInfo: null
          //   },
          //   value: ''
          // }
        ]
      },
      description: {
        id: 0,
        key: 'TABLE_WORKOUT_DESCRIPTION_95',
        resources: [
          {
            language: {
              id: 1,
              name: null,
              cultureInfo: null
            },
            value: ''
          },
          // {
          //   language: {
          //     id: 2,
          //     name: null,
          //     cultureInfo: null
          //   },
          //   value: ''
          // }
        ]
      },
      longDescription: {
        id: 0,
        key: 'TABLE_WORKOUT_LONGDESC_95',
        resources: [
          {
            language: {
              id: 1,
              name: null,
              cultureInfo: null
            },
            value: ''
          },
          // {
          //   language: {
          //     id: 2,
          //     name: null,
          //     cultureInfo: null
          //   },
          //   value: ''
          // }
        ]
      },
      image: '',
      duration: 0,
      date: '',
      level: {
        id: 4,
        name: null
      },
      idUser: 0,
      program: null,
      channels: [],
      status: 1,
      lastUpdate: '',
      activeFrom: '',
      activeUntil: '',
      workoutType: 1
    };

    //this.exercises = this.route.snapshot.data.exercises;
    this.sharedContent = this.route.snapshot.data.sharedContent;

    this.categories = this.route.snapshot.data.categories;
    this.programs = this.route.snapshot.data.programs;

    if (this.route.snapshot.data.workout === 'new') {
      this.isNew = true;
    } else {
      this.workout = this.route.snapshot.data.workout;
    }

    // this.route.params.subscribe(
    //   params => {
    //     if (params.workoutId === "new") {
    //       this.isNew = true;
    //     } else {
    //       this.workout = params.workoutId;
    //     }
    //   }
    // );

    // Cargamos exercises en lazy load
    this.exerciseService.getExercises2().subscribe(
      result => {
        this.exercises = result;
      });

  }


  // Metodos del Formulario

  onSubmit() {
    this.formSubmitted = true;
    //this.exercise.programs = [];

    // if(this.packagesSelected && this.packagesSelected.length > 0) {
    //   this.exercise.programs.push(... this.packagesSelected.map(x => x.id));
    // }

    // if(this.exercise.program && this.exercise.program.id != 0) {
    //   this.exercise.programs.push(this.exercise.program.id);
    // }

    if (!this.siteForm.form.valid) {
      window.scroll(0, 0);
    } else {
      if (this.isNew) {
        this.createWorkout();
      } else {
        this.saveWorkout();
      }
    }
  }

  // Guarda workout actual
  public saveWorkout() {
    this.saving = true;
    this.blockEditorService.putWorkout(this.workout).subscribe(
      result => {
        this.workout = result;        
        
        this.sharedContentService.putSharedContent(this.getCleanSharedContentJSON()).subscribe(
          result2 => {
            this.notificationService.notify("Workout saved successfully",
              ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
            this.saving = false;
          },
          error => {
            console.log('Error', error);
            this.saving = false;
          }
        );
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  // Crea nuevo workout
  public createWorkout() {
    this.saving = true;
    this.fixWorkoutFields();

    this.blockEditorService.postWorkout(this.workout).subscribe(
      result => {
        this.sharedContentService.putSharedContent(this.getCleanSharedContentJSON(result)).subscribe(
          result2 => {
            this.notificationService.notify("Workout saved successfully",
              ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);

            //this.saving = false;
          },
          error => {
            console.log('Error', error);
          }
        );
        this.router.navigate(["/workouts"]);
      },
      error => {
        console.log('Error', error);
        this.saving = false;
      }
    );
  }

  // Clona el workout actual a uno nuevo con las mismas características
  public cloneWorkout() {
    // Guardamos workout actual
    this.blockEditorService.putWorkout(this.workout).subscribe(
      result => {
        // Añadimos (Clone) a nombre
        this.workout.name.resources.forEach(resource => {
          resource.value += " (Clone)";
        });

        // Guardamos sharedContent
        this.sharedContentService.putSharedContent(this.getCleanSharedContentJSON()).subscribe(
          result2 => {
            // Preparamos workout
            this.cleanWorkoutforCloning();

            // Guardamos workout clonado
            this.blockEditorService.postWorkout(this.workout).subscribe(
              result3 => {
                this.sharedContentService.putSharedContent(this.getCleanSharedContentJSON(result3)).subscribe(
                  result4 => {
                    this.notificationService.notify("Workout cloned successfully",
                      ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
                  },
                  error => {
                    console.log('Error', error);
                  }
                );
                this.router.navigate(["/workouts"]);
              },
              error => {
                console.log('Error', error);
              }
            );

            // this.notificationService.notify("Workout saved successfully",
            //   ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
          },
          error => {
            console.log('Error', error);
          }
        );
      },
      error => {
        console.log('Error', error);
      }
    );

  }

  // Limpiamos workout para mandarlo como clone
  public cleanWorkoutforCloning() {
    // Cambiamos id de workout actual
    this.workout.id = 0;
    this.workout.name.id = this.workout.description.id = this.workout.longDescription.id = 0;
    // this.workout.name.resources = this.workout.name.resources.splice(1,1);
    // this.workout.description.resources = this.workout.description.resources.splice(1,1);
    // this.workout.longDescription.resources = this.workout.longDescription.resources.splice(1,1);

    // Ponemos en negativo IDs
    this.workout.channels.forEach(channel => {
      channel.id = -channel.id;
      channel.blocks.forEach(block => {
        block.id = -block.id;
        block.channel = -block.channel;
      });
    });

  }

  // Eliminamos listados del objeto sharedContent, la API no los necesita
  public getCleanSharedContentJSON(idWorkout?: number) {
    let clean = JSON.parse(JSON.stringify(this.sharedContent));
    clean.chainList = null;
    clean.gymList = null;
    clean.userList = null;
    if (idWorkout > 0) {
      clean.idWorkout = idWorkout;
    }

    return clean;
  }

  public changeProgram() {

  }

  public fixWorkoutFields() {
    this.workout.date = this.workout.activeFrom = this.workout.activeUntil = this.workout.lastUpdate = new Date().toISOString();
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

}
