import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TimelineService } from '../timeline.service';

import { of } from "rxjs";


@Injectable()
export class TimelineLogResolver implements Resolve<Observable<string>> {

  constructor(private timelineService: TimelineService) { }

  resolve(route: ActivatedRouteSnapshot) {
    
      return this.timelineService.getTimelineLogs(Number(route.paramMap.get('timelineId'))).catch(() => {
        return of('error');
      });
    
  }
}

