import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { IWorkoutMode } from "../../interfaces/models/i-workout-mode";
import { IProgram } from "../../interfaces/models/i-program";
import { ISessionRound } from "../../interfaces/models/i-session-round";
import { IStationSession } from "../../interfaces/models/sessions/i-station-session";
import { IExerciseType } from "../../interfaces/models/i-exercise-type";
import { ILevel } from "../../interfaces/models/i-level";
import { ITag } from "../../interfaces/models/i-tag";
import { IEquipment } from "../../interfaces/models/i-equipment";
import { ISession } from "../../interfaces/models/i-session";
import { IMood } from "../../interfaces/models/i-mood";
import { IPlaylist } from "../../interfaces/models/i-playlist";
import { IExercise, IExerciseSessionTable } from "../../interfaces/models/i-exercise";
import { IStation } from "../../interfaces/models/i-station";
import { ISessionAlert } from "../../interfaces/models/i-session-alert";
import { IExerciseMuscularGroup } from "../../interfaces/models/i-exercise-muscular-group";

import { SessionService } from "../../services/session.service";
import { NotificationService } from "../../services/notification.service";
import { PlaylistsService } from "../../services/playlists.service";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";
import { ISessionExercise } from "../../interfaces/models/sessions/i-session-exercise";
import { ISessionPlaylist } from "../../interfaces/models/sessions/i-session-playlist";
import { ExerciseService } from "../../services/exercise.service";

import { MatTableDataSource } from "@angular/material/table";
import { MatStepper } from "@angular/material/stepper";
import { IExerciseDiscipline } from "../../interfaces/models/i-exercise-discipline";
import { IExecutionMode } from "../../interfaces/models/i-execution-mode";


@Component({
  selector: "session-form",
  templateUrl: "./session-form.component.html",
  styleUrls: ["./session-form.component.css"]
})
export class SessionFormComponent implements OnInit, AfterViewInit {

  @ViewChild('siteForm') siteForm: any;
  @ViewChild('stepper', {static: true}) stepper: MatStepper;

  DEFAULT_WORK_TIME: number = 40;
  MOOD_OFF_ID: number = 11;
  DEFAULT_REST_TIME: number = 10;
  DEFAULT_EXTRAREST_TIME: number = 60;
  SERIE_TXT: string = "Serie";
  ROUND_TXT: string = "Round";
  SET_TXT: string = "Set";

  __session: ISession = {
    id: 0,
    name: null,
    creationDate: new Date(),
    lastUpdate: new Date(),
    program: { id: null, name: "", idDemo: 0, image: "",enableHr: true, type: 0, description: '' },
    isPreset: true,
    userId: null,
    gymId: null,
    origin: 2,
    pdf: "",
    description: null,
    workoutMode: null,
    playlists: [
      { id: 2, session: 0, playlist: null, mode: 2, round: null },
      { id: 3, session: 0, playlist: null, mode: 3, round: null },
      { id: 4, session: 0, playlist: null, mode: 4, round: null },
      { id: 5, session: 0, playlist: null, mode: 5, round: null },
      { id: 6, session: 0, playlist: null, mode: 6, round: null },

      { id: 1003, session: 0, playlist: null, mode: 3, round: 1 },
      { id: 1004, session: 0, playlist: null, mode: 4, round: 1 },
      { id: 1005, session: 0, playlist: null, mode: 5, round: 1 }
    ],
    prepare: 10,
    exercises: [],
    gymExercises: [],
    rounds: [
      { id: 1, session: 0, work: 50, rest: 10, extraRest: 0 }
    ],
    enableReps: false,
    moods: [
      { id: 1, session: 0, mood: this.MOOD_OFF_ID, mode: 1, round: null },
      { id: 2, session: 0, mood: this.MOOD_OFF_ID, mode: 2, round: null },
      { id: 3, session: 0, mood: this.MOOD_OFF_ID, mode: 3, round: null },
      { id: 4, session: 0, mood: this.MOOD_OFF_ID, mode: 4, round: null },
      { id: 5, session: 0, mood: this.MOOD_OFF_ID, mode: 5, round: null },
      { id: 6, session: 0, mood: this.MOOD_OFF_ID, mode: 6, round: null },

      { id: 1003, session: 0, mood: this.MOOD_OFF_ID, mode: 3, round: 1 },
      { id: 1004, session: 0, mood: this.MOOD_OFF_ID, mode: 4, round: 1 },
      { id: 1005, session: 0, mood: this.MOOD_OFF_ID, mode: 5, round: 1 }
    ],
    image: "",
    roundInfoTime: 0,
    roundCompletedTime: 0,
    roundPerformanceTime: 0,
    showExercisesWorkTime: 0,
    showExercisesRestTime: 0,
    type: 0,
    finisherType: undefined,
    totalTime: "",
    sessionRepetitions: 1,
    restBetweenSessionRepetitions: 0,
    executionModes: [],
    video: undefined,
    changeMusicWithPhase: false
  };
  __moodsAvailable: IMood[];

  __workoutModeTxt = "";
  __workoutModeSubTxt = "";
  __totalRounds: number = 0;
  __commonExtraRest: number = this.DEFAULT_EXTRAREST_TIME;

  _restBetweenCircuitsMood: IMood = { id: 6, session: 0, mood: this.MOOD_OFF_ID, mode: 6, round: null };
  _restBetweenCircuitsPlaylist: ISessionPlaylist = { id: 6, session: 0, playlist: null, mode: 6, round: null };

  isNew: Boolean = false;

  currentStationId: number = 0;
  lastSelectedStationId: number = 0;

  exercises: IExercise[];
  exercisesToSearch: IExercise[];
  exerciseTypesAvailable: IExerciseType[];
  complexityLevelsAvailable: ILevel[];
  tagsAvailable: ITag[];
  public workoutModesAvailable: IWorkoutMode[];
  equipment: IEquipment[];
  moodsAvailable: IMood[];
  stations: IStation[];
  stationsSession: IStationSession[];
  programs: IProgram[];
  muscularGroupsAvailable: IExerciseMuscularGroup[];
  disciplinesAvailable: IExerciseDiscipline[];

  minRest: number;
  currentSession: ISession;
  dataLoaded: boolean;
  stationsAmount: number;
  editingSession: boolean;

  mediaUrl: string = CONFIG.sessionMediaURL;
  exercisesMediaURL: string = CONFIG.exercisesMediaURL;

  currentProgramPlaylists: IPlaylist[];

  ruleCheckAlerts: ISessionAlert[] = [];

  totalExercises: number;


  // Station list
  exerciseTable: IExerciseSessionTable[];
  displayedColumns = ['stationIcon', 'stationName', 'exerciseName', 'exerciseIntensity', 'exerciseComplexity', 'exerciseDiscipline', 'exerciseMuscularGroup', 'exerciseFundamentalMovement', 'exerciseImage', 'stationOptions'];
  dataSource = new MatTableDataSource;

  executionModeList: IExecutionMode[];

  modalRef: BsModalRef;

  formSubmitted = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private sessionsService: SessionService,
    private playlistsService: PlaylistsService,
    private exerciseService: ExerciseService,
    private modalService: BsModalService
  ) {
    this.exerciseService.resetFilters("station");
  }


  ngOnInit() {
    this.loadData();
    this.loadPlaylists();
    this.checkSessionRules();
    this.changeWorkoutMode(this.__session.workoutMode ? this.__session.workoutMode : this.workoutModesAvailable[0]);
  }


  ngAfterViewInit() {
    this.refreshTableData();
  }


  refreshTableData() {
    this.exerciseTable = [];
    this.stations.forEach(station => {
      const newStation: IExerciseSessionTable = {
        station: station,
        exercise: this.getExerciseByStationId(station.id) !== null ? this.getExerciseByStationId(station.id) : null
      }
      // Remove Floor & Vertical if present
      if ((station.id !== 8 && station.id !== 9) && (station.id !== 10 || (station.id === 10 && this.totalExercises >= 8))) {
        this.exerciseTable.push(newStation);
      }
    });

    this.dataSource = new MatTableDataSource(this.exerciseTable);
  }


  loadData() {

    if (this.route.snapshot.data.session === 'new') {
      this.isNew = true;

      // Creamos ExecutionModes ausentes en new session
      this.executionModeList = this.route.snapshot.data.executionModes;
      this.executionModeList.forEach(executionMode => {
        this.__session.executionModes.push(
          {
            id: executionMode.id,
            name: executionMode.name,
            active: false
          }
        );
      });

    } else {
      this.__session = this.route.snapshot.data.session;
      this.__session.exercises.sort((a, b) => {
        if (a.station < b.station)
          return -1;
        if (a.station > b.station)
          return 1;
        return 0;
      });
    }

    this.programs = this.route.snapshot.data.programs;
    this.workoutModesAvailable = this.route.snapshot.data.workoutModesAvailable;
    this.moodsAvailable = this.route.snapshot.data.moodsAvailable;
    this.exercises = this.route.snapshot.data.exercises;
    this.stations = this.route.snapshot.data.stations;

    // Quitamos station Cardio (id:11) para que no conste en la session
    const cardioIndex = this.stations.findIndex(s => s.id === 11);
    this.stations.splice(cardioIndex, 1);

    this.muscularGroupsAvailable = this.route.snapshot.data.muscularGroupList;
    this.disciplinesAvailable = this.route.snapshot.data.disciplineList;

    this.__totalRounds = this.__session.rounds.length;

    if(this.__session.sessionRepetitions < 1) {
      this.__session.sessionRepetitions = 1;
    }

    // Check playlists. We ensure that db incongruences don't make front code a mess
    for (let i = 2; i < 5; i++) {
      if (this.__session.playlists.findIndex(x => x.mode === i) < 0) {
        const newPlaylist: ISessionPlaylist = {
          id: 0, session: this.__session.id, playlist: 0, mode: i, round: 0
        };
        this.__session.playlists.push(newPlaylist);
      }
    }

    for (let i = 3; i <= 5; i++) {
      for (let round of this.__session.rounds) {
        if (this.__session.playlists.findIndex(x => x.mode === i && x.round === round.id) < 0) {
          const newPlaylist: any = {
            id: 0, session: this.__session.id, playlist: 0, mode: i, round: round.id
          };
          this.__session.playlists.push(newPlaylist);
        }
      }
    }

    let restBetweenCircuitsPlaylist = this.__session.playlists.find(x => x.mode == 6);
    if(!restBetweenCircuitsPlaylist)
    {
      this.__session.playlists.push(this._restBetweenCircuitsPlaylist);
    }

    // Check moods. We ensure that db incongruences don't make front code a mess
    for (let i = 1; i < 5; i++) {
      if (this.__session.moods.findIndex(x => x.mode === i) < 0) {
        const newMood: any = {
          id: 0, session: this.__session.id, mood: this.MOOD_OFF_ID, mode: i, round: null
        };
        this.__session.moods.push(newMood);
      }
    }

    for (let i = 3; i <= 5; i++) {
      for (let round of this.__session.rounds) {
        if (this.__session.moods.findIndex(x => x.mode === i && x.round === round.id) < 0) {
          const newMood: any = {
            id: 0, session: this.__session.id, mood: 0, mode: i, round: round.id
          };
          this.__session.moods.push(newMood);
        }
      }
    }

    let restBetweenCircuitsMood = this.__session.moods.find(x => x.mode == 6);
    if(!restBetweenCircuitsMood)
    {
      this.__session.moods.push(this._restBetweenCircuitsMood);
    }


    if (this.isNew) {
      const newWorkoutMode: IWorkoutMode = { id: 0, name: "" };
      this.__session.workoutMode = newWorkoutMode;
    }

    if (this.isRepsEnabled()) {
      this.__session.enableReps = true;
    }

    this.__commonExtraRest = this.__session.rounds[this.__session.rounds.length - 1].extraRest;
    this.totalExercises = this.getExerciseCount() > 7 ? this.getExerciseCount() : 7;
    this.recalculateTimes();
    this.fillGymExercises();
  }


  fillGymExercises() {
    this.__session.gymExercises.length = 0;
    for (const exercise of this.__session.exercises) {

      if(exercise.rookieReps && exercise.rookieReps === -1) {
        exercise.rookieAMRAP = true;
        exercise.rookieReps = undefined;
      }

      if(exercise.athleteReps && exercise.athleteReps === -1) {
        exercise.athleteAMRAP = true;
        exercise.athleteReps = undefined;
      }

      this.__session.gymExercises.push(this.exercises.find(x => x.id === exercise.exercise));
    }
  }


  searchExercise(stationId: number, event) {
    this.currentStationId = stationId;
    // Comentado todo el bloque para hacer OVERRIDE del selector de ejercicios por estación
    // if (this.currentStationId === 6) {
    //   this.exercisesToSearch = this.exercises.filter(x => (x.station !== null && x.station !== undefined && (x.station.id === 6 || x.station.id === 8 || x.station.id === 9)) || x.alwaysVisible);
    // } else {
    //   this.exercisesToSearch = this.exercises.filter(x => (x.station !== null && x.station.id === stationId) || x.alwaysVisible);
    // }
    // if (this.countExercisesInSession() > 0 && this.__session.workoutMode.id != 4) {
    //   this.exercisesToSearch = this.applyExerciseRules(this.currentStationId, this.exercisesToSearch);
    // }
    this.exercisesToSearch = this.exercises;
  }


  applyExerciseRules(stationId: number, exerciseList: IExercise[]) {
    let auxStation = this.__session.exercises.find(x => x.station === this.getPreviousActiveStation(stationId));

    let prevStation1 = this.getPreviousActiveStation(stationId);
    let prevStation2 = this.getPreviousActiveStation(prevStation1);
    let nextStation1 = this.getNextActiveStation(stationId);
    let nextStation2 = this.getNextActiveStation(nextStation1);

    let previousExercise = this.getExerciseByStationId(prevStation1);
    let previousExercise2 = this.getExerciseByStationId(prevStation2);
    let nextExercise = this.getExerciseByStationId(nextStation1);
    let nextExercise2 = this.getExerciseByStationId(nextStation2);

    let auxExercises: IExercise[];
    auxExercises = exerciseList;

    switch (this.__session.program.id) {
      case 1: // Energy

        // noMoreThanThreeExercisesWithPrimaryFMType3
        if (CONFIG.sessionRules.programEnergy.noMoreThanThreeExercisesWithPrimaryFMType3) {
          if (this.countPropertyInSession({ id: 3, field: "fundamentalMovement1.idCategory" }) >= 3) {
            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.fundamentalMovement1) {
                return (x.fundamentalMovement1.idCategory === 3)
              }
            }), "No more than 3 exercises with primary movement type 3");
          }
        }

        // noMoreThanThreeExercisesWithPrimaryFMType2
        if (CONFIG.sessionRules.programEnergy.noMoreThanThreeExercisesWithPrimaryFMType2) {
          if (this.countPropertyInSession({ id: 2, field: "fundamentalMovement1.idCategory" }) >= 4) {
            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.fundamentalMovement1) {
                return (x.fundamentalMovement1.idCategory === 2)
              }
            }), "No more than 3 exercises with primary movement type 2");
          }
        }

        // noMoreThanThreeExercisesWithPrimaryFMType1
        if (CONFIG.sessionRules.programEnergy.noMoreThanThreeExercisesWithPrimaryFMType1) {
          if (this.countPropertyInSession({ id: 1, field: "fundamentalMovement1.idCategory" }) >= 3) {
            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.fundamentalMovement1) {
                return (x.fundamentalMovement1.idCategory === 1)
              }
            }), "No more than 3 exercises with primary movement type 1");
          }
        }

        // noTwoConsecutiveHighIntensityExercises
        if (CONFIG.sessionRules.programEnergy.noTwoConsecutiveHighIntensityExercises) {
          if (
            (previousExercise && previousExercise.intensityLevel && previousExercise.intensityLevel.id >= 5 && previousExercise.intensityLevel.id <= 6)
            || (nextExercise && nextExercise.intensityLevel && nextExercise.intensityLevel.id >= 5 && nextExercise.intensityLevel.id <= 6)
          ) {

            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.intensityLevel) {
                return (x.intensityLevel.id >= 5 && x.intensityLevel.id <= 6)
              }
            }), "Never two consecutive high intensity exercises");
          }
        }

        //noTwoConsecutiveLowIntensityExercises
        if (CONFIG.sessionRules.programEnergy.noTwoConsecutiveLowIntensityExercises) {
          if (
            (previousExercise && previousExercise.intensityLevel && previousExercise.intensityLevel.id >= 2 && previousExercise.intensityLevel.id <= 3)
            || (nextExercise && nextExercise.intensityLevel && nextExercise.intensityLevel.id >= 2 && nextExercise.intensityLevel.id <= 3)
          ) {

            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.intensityLevel) {
                return (x.intensityLevel.id >= 2 && x.intensityLevel.id <= 3)
              }
            }), "Never two consecutive low intensity exercises");
          }
        }

        // noThreeConsecutiveExercisesWithSamePrimaryFM
        if (CONFIG.sessionRules.programEnergy.noThreeConsecutiveExercisesWithSamePrimaryFM) {
          if (previousExercise && previousExercise2 && previousExercise.fundamentalMovement1 && previousExercise2.fundamentalMovement1 && previousExercise.fundamentalMovement1.id === previousExercise2.fundamentalMovement1.id && this.countExercisesInSession() >= 2) {
            //exerciseList = exerciseList.filter(x => x.fundamentalMovement1 !== previousExercise.fundamentalMovement1);
            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.fundamentalMovement1) {
                return (x.fundamentalMovement1.id === previousExercise.fundamentalMovement1.id)
              }
            }), "Never three consecutive exercises with the same primary FM");
          }
        }

        break;

      case 2: // Strong

        // noThreeConsecutiveExercisesWithSamePrimaryMG
        if (CONFIG.sessionRules.programStrength.noThreeConsecutiveExercisesWithSamePrimaryMG) {
          if (previousExercise && previousExercise2 && previousExercise.muscularGroup1 && previousExercise2.muscularGroup1 && previousExercise.muscularGroup1.id === previousExercise2.muscularGroup1.id && this.countExercisesInSession() >= 2) {
            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.muscularGroup1) {
                return (x.muscularGroup1.id === previousExercise.muscularGroup1.id)
              }
            }), "Never three consecutive exercises with the same primary FM");
          }
        }

        // noMoreThanFourExercisesWithSamePrimaryMG
        if (CONFIG.sessionRules.programStrength.noMoreThanFourExercisesWithSamePrimaryMG) {
          for (const station of this.__session.gymExercises) {
            if (this.countPropertyInSession({ id: station.muscularGroup1.id, field: "muscularGroup1.id" }) >= 4) {
              this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
                if (x.muscularGroup1) {
                  return (x.muscularGroup1.id === previousExercise.muscularGroup1.id)
                }
              }), "No more than 4 exercises with the same primary MG");
            }
          }
        }

        // noMoreThanSixExercisesWithSameMGPrimaryOrSecondary
        if (CONFIG.sessionRules.programStrength.noMoreThanSixExercisesWithSameMGPrimaryOrSecondary) {
          let count = 0;
          for (const station of this.__session.gymExercises) {
            count = this.countPropertyInSession({ id: station.muscularGroup1.id, field: "muscularGroup1.id" }) > count ? this.countPropertyInSession({ id: station.muscularGroup1.id, field: "muscularGroup1.id" }) : count;
            //count += this.countPropertyInSession({ id: station.muscularGroup1.id, field: "muscularGroup1.id" });
            count = this.countPropertyInSession({ id: station.muscularGroup1.id, field: "muscularGroup2" }) > count ? this.countPropertyInSession({ id: station.muscularGroup1.id, field: "muscularGroup2" }) : count;
            //count += this.countPropertyInSession({ id: station.muscularGroup1.id, field: "muscularGroup2" });

            for (const muscularGroup of station.muscularGroup2) {
              count += this.countPropertyInSession({ id: muscularGroup.id, field: "muscularGroup1.id" });
              count += this.countPropertyInSession({ id: muscularGroup.id, field: "muscularGroup2" });

              count = this.countPropertyInSession({ id: muscularGroup.id, field: "muscularGroup1.id" }) > count ? this.countPropertyInSession({ id: muscularGroup.id, field: "muscularGroup1.id" }) : count;
              count = this.countPropertyInSession({ id: muscularGroup.id, field: "muscularGroup2" }) > count ? this.countPropertyInSession({ id: muscularGroup.id, field: "muscularGroup2" }) : count;
            }

            if (count > 6) {
              this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
                if (x && x.muscularGroup1) {
                  return (x.muscularGroup1.id === previousExercise.muscularGroup1.id)
                }
              }), "No more than 6 exercises with the same MG");
            }
          }
        }

        break;

      case 4: // Athletic

        // neverZoneWithBothExercisesSameDiscipline
        if (CONFIG.sessionRules.programAthletic.neverZoneWithBothExercisesSameDiscipline) {

          const pairs = { 1: 2, 2: 1, 3: 4, 4: 3, 5: 6, 6: 5, 7: 10, 10: 7 };

          const pairedstation: IExercise = this.__session.gymExercises.find(x => x.station.id === pairs[stationId]);
          if (pairedstation && pairedstation.discipline !== null) {
            const aux = this.exercisesToSearch.filter(x => {
              if (x && x.discipline && pairedstation.discipline) {
                return x.discipline.id !== pairedstation.discipline.id;
              }
            });
            this.assignArray(this.exercisesToSearch, aux);
          }
        }

      case 3: // Flow

        // noThreeConsecutiveSameDisciplineExercises
        if (CONFIG.sessionRules.programFlow.noThreeConsecutiveSameDisciplineExercises) {
          if (previousExercise && previousExercise2 && previousExercise.discipline && previousExercise2.discipline && previousExercise.discipline.id === previousExercise2.discipline.id && this.countExercisesInSession() >= 2) {
            this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
              if (x.discipline) {
                return (x.discipline.id === previousExercise.discipline.id)
              }
            }), "Never three consecutive exercises of the same discipline");
          }
        }

        // noMoreThanFourExercisesSameDiscipline
        if (CONFIG.sessionRules.programFlow.noMoreThanFourExercisesSameDiscipline) {
          for (const station of this.__session.gymExercises) {
            if (station.discipline && this.countPropertyInSession({ id: station.discipline.id, field: "discipline.id" }) >= 4) {
              this.markExercisesAsFailed(this.exercisesToSearch.filter(x => {
                if (x.discipline) {
                  return (x.discipline.id === previousExercise.discipline.id)
                }
              }), "No more than 4 exercises of the same discipline");
            }
          }
        }
    }

    auxExercises.sort(this.compareRuleAmount);

    return auxExercises;
  }


  compareRuleAmount(a, b) {
    if (!a.ruleStatus) {
      a.ruleStatus = {
        ruleAmount: 0,
        rules: []
      };
    }
    if (!b.ruleStatus) {
      b.ruleStatus = {
        ruleAmount: 0,
        rules: []
      };
    }

    if (a.ruleStatus.ruleAmount < b.ruleStatus.ruleAmount)
      return -1;
    if (a.ruleStatus.ruleAmount > b.ruleStatus.ruleAmount)
      return 1;
    return 0;

  }


  // Copy an array to another with no reference loss
  assignArray(origin: any[], dest: any[]) {
    origin.length = 0;
    for (const item of dest) {
      origin.push(item);
    }
  }


  // Changes exercises ruleStatus to mark them as 'do not complies with rule'
  markExercisesAsFailed(exercises: IExercise[], ruleTxt: string) {
    for (const exercise of exercises) {
      if (!exercise.ruleStatus) {
        exercise.ruleStatus = {
          ruleAmount: 0,
          rules: []
        };
      }

      if (!exercise.ruleStatus.rules.includes(ruleTxt)) {
        exercise.ruleStatus.ruleAmount++;
        exercise.ruleStatus.rules.push(ruleTxt);
      }
    }
  }


  checkSessionRules() {

    let totalExercises = this.countExercisesInSession();
    let exercisesMet: number = 0;
    this.ruleCheckAlerts = [];

    if (Number(this.__session.program.id) === 1) { // Energy

      // noMore5ExercisesWithSameFM
      if (CONFIG.sessionRules.programEnergy.noMore5ExercisesWithSameFM) {
        let maxCoincidences, maxAux;

        maxCoincidences = 0;
        for (let i = 0; i < this.__session.gymExercises.length - 1; i++) {
          maxAux = 0;
          for (let j = i + 1; j < this.__session.gymExercises.length; j++) {
            if (this.__session.gymExercises[i].fundamentalMovement1
              && this.__session.gymExercises[j].fundamentalMovement1
              && this.__session.gymExercises[i].fundamentalMovement1.id === this.__session.gymExercises[j].fundamentalMovement1.id) {
              maxAux++;
            }
          }
          if (this.__session.gymExercises[i].fundamentalMovement2) {
            this.__session.gymExercises[i].fundamentalMovement2.forEach((fm) => {
              if (this.__session.gymExercises[i].fundamentalMovement1 && this.__session.gymExercises[i].fundamentalMovement1.id === fm.id) {
                maxAux++;
              }
            });
          }
          maxCoincidences = maxCoincidences < maxAux ? maxAux : maxCoincidences;
        }
        if (maxCoincidences > 5) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: No more than 5 exercises with the same FM (currently " + maxCoincidences + ")" });
        }
      }

      // atLeast2HighIntensityExercises
      if (CONFIG.sessionRules.programEnergy.atLeast2HighIntensityExercises) {
        let coincidences = 0;
        this.__session.gymExercises.forEach(exercise => {
          if (exercise.intensityLevel && exercise.intensityLevel.id >= 5 && exercise.intensityLevel.id <= 6) {
            coincidences++;
          }
        });

        if (coincidences < 2) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: At least two high intensity exercises needed (" + coincidences + " of 2)" });
        }
      }

      // FMType123ShouldAppear
      if (CONFIG.sessionRules.programEnergy.type123PrimaryFMShouldAppear) {
        let coincidences1 = 0;
        let coincidences2 = 0;
        let coincidences3 = 0;
        this.__session.gymExercises.forEach(exercise => {
          if (exercise.fundamentalMovement1 && exercise.fundamentalMovement1.idCategory === 1) {
            coincidences1++;
          }
          if (exercise.fundamentalMovement1 && exercise.fundamentalMovement1.idCategory === 2) {
            coincidences2++;
          }
          if (exercise.fundamentalMovement1 && exercise.fundamentalMovement1.idCategory === 3) {
            coincidences3++;
          }
        });

        if (coincidences1 === 0) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: Fundamental movement type 1 should appear" });
        }
        if (coincidences2 === 0) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: Fundamental movement type 2 should appear" });
        }
        if (coincidences3 === 0) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: Fundamental movement type 3 should appear" });
        }
      }

    }

    if (Number(this.__session.program.id) === 2) { // Strength
      // moreThan60PerCentMGShouldAppear
      if (CONFIG.sessionRules.programStrength.moreThan60PerCentMGShouldAppear) {
        let foundMG: IExerciseMuscularGroup[] = [];

        for (const exercise of this.__session.gymExercises) {
          if (foundMG.findIndex(x => x.id === exercise.muscularGroup1.id) === -1)
            foundMG.push(exercise.muscularGroup1);
        }

        const percentage = foundMG.length * 100 / this.muscularGroupsAvailable.length;
        if (percentage < 60) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: More than 60% of muscle groups should appear (currently " + Math.floor(percentage) + "%)" });
        }
      }
    }

    if (Number(this.__session.program.id) === 4) { // Athletics
      // noMoreThanTwoStrengthExercisesWithSameFM
      if (CONFIG.sessionRules.programAthletic.noMoreThanTwoStrengthExercisesWithSameFM) {
        let maxCoincidences, maxAux;

        maxCoincidences = 0;
        for (let i = 0; i < this.__session.gymExercises.length - 1; i++) {

          if (this.__session.gymExercises[i].program && this.__session.gymExercises[i].program.id === 2) {
            maxAux = 0;
            for (let j = i + 1; j < this.__session.gymExercises.length; j++) {
              if (this.__session.gymExercises[i].fundamentalMovement1
                && this.__session.gymExercises[j].fundamentalMovement1
                && this.__session.gymExercises[i].fundamentalMovement1.id === this.__session.gymExercises[j].fundamentalMovement1.id) {
                maxAux++;
              }
            }
            maxCoincidences = maxCoincidences < maxAux ? maxAux : maxCoincidences;
          }

        }
        if (maxCoincidences > 2) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: No more than 2 strength exercises with the same primary FM (currently " + maxCoincidences + ")" });
        }
      }

      // noMoreThanTwoStrengthExercisesWithSameMG
      if (CONFIG.sessionRules.programAthletic.noMoreThanTwoStrengthExercisesWithSameMG) {
        let maxCoincidences, maxAux;

        maxCoincidences = 0;
        for (let i = 0; i < this.__session.gymExercises.length - 1; i++) {
          if (this.__session.gymExercises[i].program && this.__session.gymExercises[i].program.id === 2) {
            maxAux = 0;
            for (let j = i + 1; j < this.__session.gymExercises.length; j++) {
              if (this.__session.gymExercises[i].muscularGroup1
                && this.__session.gymExercises[j].muscularGroup1
                && this.__session.gymExercises[i].muscularGroup1.id === this.__session.gymExercises[j].muscularGroup1.id) {
                maxAux++;
              }
            }
            maxCoincidences = maxCoincidences < maxAux ? maxAux : maxCoincidences;
          }
        }
        if (maxCoincidences > 2) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: No more than 2 strength exercises with the same primary MG (currently " + maxCoincidences + ")" });
        }
      }
    }

    if (Number(this.__session.program.id) === 3) { // Flow
      // atLeastTwoExercisesEachDiscipline
      if (CONFIG.sessionRules.programFlow.atLeastTwoExercisesEachDiscipline) {
        let ruleMet = true;
        for (const discipline of this.disciplinesAvailable) {
          if (this.__session.gymExercises.filter(x => x.discipline.id === discipline.id).length < 2) {
            ruleMet = false;
          }
        }

        if (!ruleMet) {
          this.ruleCheckAlerts.push({ id: this.ruleCheckAlerts.length, message: "Suggestion: At least 2 exercises of each discipline" });
        }
      }
    }
  }


  // List of session rules, executed when an exercise is selected
  checkSessionRules_OLD() {
    this.ruleCheckAlerts = [];

    if (Number(this.__session.program.id) === 1) { // Energy
      // atLeast25PerCentHighIntensityExercises // REVISADO Y OK
      if (CONFIG.sessionRules.programEnergy.atLeast25PerCentHighIntensityExercises) {
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "intensityLevel", minValue: 5, maxValue: 6, alertId: 10, alertMessage: "Required: Minimum 25% of HI exercises" });
      }
      // noMore40PerCentExercisesWithPrimaryFMType3
      if (CONFIG.sessionRules.programEnergy.noMore40PerCentExercisesWithPrimaryFMType3) {
        this.checkExerciseRule({ percentage: 40, rule: "max", field: "fundamentalMovement1.idCategory", minValue: 3, maxValue: 3, alertId: 20, alertMessage: "Required: Maximum 40% of exercises with PM type 3" });
      }
      // noMore50PerCentExercisesWithPrimaryFMType2
      if (CONFIG.sessionRules.programEnergy.noMore50PerCentExercisesWithPrimaryFMType2) {
        this.checkExerciseRule({ percentage: 50, rule: "max", field: "fundamentalMovement1.idCategory", minValue: 2, maxValue: 2, alertId: 30, alertMessage: "Required: Maximum 50% of exercises with PM type 2" });
      }
      // noMore40PerCentExercisesWithPrimaryFMType1
      if (CONFIG.sessionRules.programEnergy.noMore40PerCentExercisesWithPrimaryFMType1) {
        this.checkExerciseRule({ percentage: 40, rule: "max", field: "fundamentalMovement1.idCategory", minValue: 1, maxValue: 1, alertId: 40, alertMessage: "Required: Maximum 40% of exercises with PM type 1" });
      }
      // noMore65PerCentExercisesWithSameFM
      if (CONFIG.sessionRules.programEnergy.noMore65PerCentExercisesWithSameFM) {
        //        this.checkExerciseRule({ percentage: 65, rule: "max", sameValue: true, field1: "fundamentalMovement1", field2: "fundamentalMovement2", minValue: 1, maxValue: 1, alertId: 40, alertMessage: "Required: Maximum 40% of exercises with PM type 1" });
      }
      // allFMTypesMustAppear // REVISADO Y OK
      if (CONFIG.sessionRules.programEnergy.allFMTypesMustAppear) {
        this.checkExerciseRule({ amount: 1, rule: "min", field: "fundamentalMovement1.idCategory", minValue: 1, maxValue: 1, alertId: 51, alertMessage: "Required: Exercise with primary movement category 1 must appear" });
        this.checkExerciseRule({ amount: 1, rule: "min", field: "fundamentalMovement1.idCategory", minValue: 2, maxValue: 2, alertId: 52, alertMessage: "Required: Exercise with primary movement category 2 must appear" });
        this.checkExerciseRule({ amount: 1, rule: "min", field: "fundamentalMovement1.idCategory", minValue: 3, maxValue: 3, alertId: 53, alertMessage: "Required: Exercise with primary movement category 3 must appear" });
      }

      // minOneSkillsExercise
      if (CONFIG.sessionRules.programEnergy.minOneSkillsExercise) {
        this.checkExerciseRule({ amount: 1, rule: "min", field: "skills", alertId: 60, alertMessage: "Required: At least 1 exercise with skills must appear" });
      }
    }

    if (Number(this.__session.program.id) === 2) { // Strength
      // atLeast25PerCentHighIntensityExercises
      if (CONFIG.sessionRules.programStrength.atLeast25PerCentHighIntensityExercises) {
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "intensityLevel", minValue: 5, maxValue: 6, alertId: 70, alertMessage: "Required: Minimum 25% of High intensity exercises" });
      }

      // noMore25PerCentLowIntensityExercises
      if (CONFIG.sessionRules.programStrength.noMore25PerCentLowIntensityExercises) {
        this.checkExerciseRule({ percentage: 25, rule: "max", field: "intensityLevel", minValue: 2, maxValue: 3, alertId: 80, alertMessage: "Required: Maximum 25% of Low intensity exercises" });
      }
    }

    if (Number(this.__session.program.id) === 4) { // Athletics
      // atLeast25PerCentExercisesEachDiscipline
      if (CONFIG.sessionRules.programAthletic.atLeast25PerCentExercisesEachDiscipline) {
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "discipline", minValue: 1, maxValue: 1, alertId: 91, alertMessage: "Required: Minimum 25% of discipline CARDIO" });
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "discipline", minValue: 2, maxValue: 2, alertId: 92, alertMessage: "Required: Minimum 25% of discipline SPORTS" });
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "discipline", minValue: 3, maxValue: 3, alertId: 93, alertMessage: "Required: Minimum 25% of discipline STRENGTH" });
      }
      // noMore50PerCentExercisesSameDiscipline
      if (CONFIG.sessionRules.programAthletic.noMore50PerCentExercisesSameDiscipline) {
        this.checkExerciseRule({ percentage: 50, rule: "max", field: "discipline", minValue: 1, maxValue: 1, alertId: 94, alertMessage: "Required: Maximum 50% of discipline CARDIO" });
        this.checkExerciseRule({ percentage: 50, rule: "max", field: "discipline", minValue: 2, maxValue: 2, alertId: 95, alertMessage: "Required: Maximum 50% of discipline SPORTS" });
        this.checkExerciseRule({ percentage: 50, rule: "max", field: "discipline", minValue: 3, maxValue: 3, alertId: 96, alertMessage: "Required: Maximum 50% of discipline STRENGTH" });
      }
    }

    if (Number(this.__session.program.id) === 3) { // Flow
      // atLeast25PerCentExercisesEachDiscipline
      if (CONFIG.sessionRules.programFlow.atLeast25PerCentExercisesEachDiscipline) {
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "discipline", minValue: 3, maxValue: 3, alertId: 101, alertMessage: "Required: Minimum 25% of discipline STRENGTH" });
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "discipline", minValue: 4, maxValue: 4, alertId: 102, alertMessage: "Required: Minimum 25% of discipline MOBILITY" });
        this.checkExerciseRule({ percentage: 25, rule: "min", field: "discipline", minValue: 5, maxValue: 5, alertId: 103, alertMessage: "Required: Minimum 25% of discipline BALANCE" });
      }
      // noMore50PerCentExercisesSameDiscipline
      if (CONFIG.sessionRules.programFlow.noMore50PerCentExercisesSameDiscipline) {
        this.checkExerciseRule({ percentage: 50, rule: "max", field: "discipline", minValue: 1, maxValue: 1, alertId: 104, alertMessage: "Required: Maximum 50% of discipline STRENGTH" });
        this.checkExerciseRule({ percentage: 50, rule: "max", field: "discipline", minValue: 2, maxValue: 2, alertId: 105, alertMessage: "Required: Maximum 50% of discipline MOBILITY" });
        this.checkExerciseRule({ percentage: 50, rule: "max", field: "discipline", minValue: 3, maxValue: 3, alertId: 106, alertMessage: "Required: Maximum 50% of discipline BALANCE" });
      }
    }
  }


  // Inits and checks a rule. Also manages alerts
  checkExerciseRule(params) {
    let check;
    if ((params.minValue !== undefined && params.maxValue !== undefined && (params.sameValue === false || params.sameValue === undefined)) || params.amount !== undefined) {
      check = this.checkExercisePercentage(params);
    }
    if ((params.minValue !== undefined && params.maxValue !== undefined && params.sameValue === true)) {
      check = this.checkExercisePercentageSameValue(params);
    }

    if (!check) {
      const message: ISessionAlert = { id: params.alertId, message: params.alertMessage };
      if (this.ruleCheckAlerts.findIndex(x => x.id === params.alertId) === -1) {
        this.ruleCheckAlerts.push(message);
      }
    } else {
      const i = this.ruleCheckAlerts.findIndex(x => x.id === params.alertId);
      if (i > -1) {
        this.ruleCheckAlerts.splice(i, 1);
      }
    }
  }


  // Returns true if percentage rule for an exercise is satisfied
  checkExercisePercentage(params) {
    let exerciseMatches = 0;
    for (const station of this.__session.gymExercises) {
      //if (station !== undefined) {
      let finalField;
      if (params.field.indexOf(".") > 0) {
        finalField = station[params.field.split(".")[0]][params.field.split(".")[1]];
      } else {
        finalField = station[params.field];
      }
      if (params.minValue && params.maxValue) {
        if ((finalField.id !== undefined && (finalField.id >= params.minValue && finalField.id <= params.maxValue))
          || (finalField.id === undefined && (finalField >= params.minValue && finalField <= params.maxValue))) {
          exerciseMatches++;
        }
      } else
        if (params.minValue === undefined && params.maxValue === undefined) {
          exerciseMatches++;
        }
      //}
    }

    let calculatedPercentage = 0;
    if (params.percentage) {
      if (this.countExercisesInSession() > 0) {
        calculatedPercentage = exerciseMatches * 100 / this.countExercisesInSession();
      } else {
        calculatedPercentage = 0;
      }
    }

    switch (params.rule) {
      case "max":
        if ((calculatedPercentage <= params.percentage && params.percentage !== undefined) || (exerciseMatches <= params.amount)) {
          return true;
        } else {
          return false;
        }
      case "min":
        if ((calculatedPercentage >= params.percentage && params.percentage !== undefined) || (exerciseMatches >= params.amount)) {
          return true;
        } else {
          return false;
        }
    }
  }


  // Returns true if percentage rule for an exercise is satisfied
  checkExercisePercentageSameValue(params) {
    let exerciseMatches = 0;
    for (let i = 0; i < this.stationsSession.length; i++) {
      for (let j = i; j < this.stationsSession.length; j++) {
        if (this.stationsSession[i].exercise !== undefined) {
          let comp1, comp2, comp3, comp4;

          if (params.field !== undefined && params.field.indexOf(".") > 0) {
            comp1 = this.stationsSession[i].exercise.exercise[params.field1.split(".")[0]][params.field.split(".")[1]];
            comp2 = this.stationsSession[j].exercise.exercise[params.field1.split(".")[0]][params.field.split(".")[1]];
            comp3 = this.stationsSession[i].exercise.exercise[params.field2.split(".")[0]][params.field.split(".")[1]];
            comp4 = this.stationsSession[j].exercise.exercise[params.field2.split(".")[0]][params.field.split(".")[1]];
          } else {
            comp1 = this.stationsSession[i].exercise.exercise[params.field1];
            comp2 = this.stationsSession[j].exercise.exercise[params.field1];
            comp3 = this.stationsSession[i].exercise.exercise[params.field2];
            comp4 = this.stationsSession[j].exercise.exercise[params.field2];
            if (this.stationsSession[i] !== this.stationsSession[j] && (comp1 === comp2 || comp1 === comp3 || comp1 === comp4 || comp2 === comp3 || comp2 === comp4 || comp3 === comp4)) {
              exerciseMatches++;
            }
          }
        }
      }
    }

    let calculatedPercentage = 0;
    if (params.percentage) {
      if (this.countExercisesInSession() > 0) {
        calculatedPercentage = exerciseMatches * 100 / this.countExercisesInSession();
      } else {
        calculatedPercentage = 0;
      }
    }

    switch (params.rule) {
      case "max":
        if ((calculatedPercentage <= params.percentage && params.percentage !== undefined) || (exerciseMatches <= params.amount)) {
          return true;
        } else {
          return false;
        }
      case "min":
        if ((calculatedPercentage >= params.percentage && params.percentage !== undefined) || (exerciseMatches >= params.amount)) {
          return true;
        } else {
          return false;
        }
    }
  }


  // Counts # of the same property in current session
  countPropertyInSession(params) {
    let count = 0;
    let finalField;

    for (const station of this.__session.gymExercises) {

      if (params.field.indexOf(".") > 0) {
        if (station[params.field.split(".")[0]]) {
          finalField = station[params.field.split(".")[0]][params.field.split(".")[1]];
        }
      } else {
        finalField = station[params.field];
      }

      if (station !== undefined) {
        if (Array.isArray(finalField)) {
          for (const property of finalField) {
            if (property === params.id) {
              count++;
            }
          }
        } else {
          if (finalField === params.id) {
            count++;
          }
        }
      }
    }
    return count;
  }


  getPreviousActiveStation(stationId: number) {
    let order;
    if (this.totalExercises >= 8) {
      order = [1, 2, 3, 4, 5, 6, 7, 10];
    } else {
      order = [1, 2, 3, 4, 5, 6, 7];
    }
    let orderPos = order.findIndex(x => x === stationId);
    do {
      orderPos--;
      if (orderPos < 0) {
        orderPos = order.length - 1;
      }

    } while (this.__session.exercises.filter(x => x.station === order[orderPos])[0] !== undefined && this.__session.exercises.filter(x => x.station === order[orderPos])[0].exercise === undefined);

    return order[orderPos];
  }


  getNextActiveStation(stationId: number) {
    let order;
    if (this.totalExercises >= 8) {
      order = [1, 2, 3, 4, 5, 6, 7, 10];
    } else {
      order = [1, 2, 3, 4, 5, 6, 7];
    }
    let orderPos = order.findIndex(x => x === stationId);
    do {
      orderPos++;
      if (orderPos > order.length - 1) {
        orderPos = 0;
      }

    } while (this.__session.exercises.filter(x => x.station === order[orderPos])[0] !== undefined && this.__session.exercises.filter(x => x.station === order[orderPos])[0].exercise === undefined);

    return order[orderPos];
  }


  countExercisesInSession() {
    let count = 0;
    for (const exercise of this.__session.exercises) {
      if (exercise.exercise !== undefined) {
        count++;
      }
    }
    return count;
  }


  closeModal() {
    //const modal = document.getElementById("modal-container");
    //modal.classList.remove("uk-open");
    //modal.style.display = "none";
  }


  exerciseSelected(exercise: IExercise) {
    this.closeModal();
    let i = this.__session.exercises.findIndex(x => x.station === this.currentStationId);
    let ie = this.__session.gymExercises.findIndex(x => {
      if (x.station) {
        return x.station.id === this.currentStationId
      }
    });

    if (i >= 0) {
      this.__session.exercises[i].exercise = exercise.id;
      this.__session.gymExercises[ie] = exercise;
    } else {
      const newExercise: ISessionExercise = {
        exerciseId: 0,
        exercise: exercise.id,
        reps: 0,
        stationId: this.currentStationId,
        station: this.currentStationId,
        athleteReps: 0,
        rookieReps: 0,
        athleteAMRAP: false,
        rookieAMRAP: false,
      }
      this.__session.exercises.push(newExercise);
      this.__session.gymExercises.push(exercise);
    }

    // Calculate next station in list
    let stationCounter = this.currentStationId;
    if (!this.isSessionFull()) {
      while (this.getExerciseByStationId(this.getNextActiveStation(stationCounter))) {
        stationCounter = this.getNextActiveStation(stationCounter);
      }
    }
    if (stationCounter !== null) {
      this.lastSelectedStationId = stationCounter;
    }

    this.checkSessionRules();
    this.refreshTableData();

    console.log(this.__session.exercises);
  }


  isSessionFull() {
    return this.__session.gymExercises.length === this.totalExercises;
  }


  cleanStation(stationId) {
    let indexExercise = this.__session.exercises.findIndex(x => x.station === stationId);
    if (indexExercise >= 0) {

      // Remove from gymExercises
      let indexGymExercise = this.__session.gymExercises.findIndex(x => x.id === this.__session.exercises[indexExercise].exercise);
      if (indexGymExercise >= 0) {
        this.__session.gymExercises.splice(indexGymExercise, 1);
      }

      // Remove from exercises
      if (indexExercise >= 0) {
        this.__session.exercises.splice(indexExercise, 1);
      }
    }

    this.checkSessionRules();
    this.refreshTableData();
  }


  searchCancelled() {
    this.closeModal();
  }


  repsModeChanged() {
    // if (!this.sessionValues.enableReps) {
    //   this.stationsSession.forEach(x => {
    //     if (x.exercise) {
    //       x.exercise.reps = 0;
    //     }
    //   });
    // }
  }

  getMoodIndexByRound(round: number): number {
    return this.__session.moods.findIndex(x => x.round === round) >= 0 ? this.__session.moods.findIndex(x => x.round === round) : 0;
  }


  getMoodIndexByMode(mode: number): number {
    return this.__session.moods.findIndex(x => x.mode === mode) >= 0 ? this.__session.moods.findIndex(x => x.mode === mode) : 0;
  }


  getMoodByRoundAndMode(round: number, mode: number): IMood {
    return this.__session.moods.find(x => x.round === round && x.mode === mode);
  }


  getMoodImageName(mood: IMood): string {
    let moodStr: string;
    if (mood.mood === null) {
      moodStr = "0";
    } else {
      moodStr = mood.mood.toString();
    }
    moodStr += ".png"
    return moodStr;
  }


  getMoodImageFullPath(mood: IMood): string {
    return "assets/moods/" + this.getMoodImageName(mood);
  }


  getPlaylistIndexByMode(mode: number): number {
    return this.__session.playlists.findIndex(x => x.mode === mode) >= 0 ? this.__session.playlists.findIndex(x => x.mode === mode) : 0;
  }


  getPlaylistByRoundAndMode(round: number, mode: number): ISessionPlaylist {
    return this.__session.playlists.find(x => x.round === round && x.mode === mode);
  }


  globalMoodChange($event?) {
    for (let round of this.__session.rounds) {
      for (let mode = 3; mode <= 5; mode++) {
        if (mode !== 5) {
          this.getMoodByRoundAndMode(round.id, mode).mood = this.getMoodByRoundAndMode(null, mode).mood;
        } else {
          this.getMoodByRoundAndMode(round.id, mode).mood = this.getMoodByRoundAndMode(null, 4).mood;
        }
      }
    }
  }


  globalPlaylistChange($event?) {
    for (let round of this.__session.rounds) {
      for (let mode = 3; mode <= 5; mode++) {
        this.getPlaylistByRoundAndMode(round.id, mode).playlist = this.getPlaylistByRoundAndMode(null, mode).playlist;
      }
    }
  }


  public changeWorkoutMode(workoutMode: IWorkoutMode) {
    if (this.__session) {
      this.__session.workoutMode = workoutMode;
      let columns = [];
      let workoutModeText = '';
      let workoutModeSubText = '';

      switch (this.__session.workoutMode.id) {
        case 1:
          workoutModeText = this.ROUND_TXT;
          workoutModeText = this.ROUND_TXT;
          columns = ['stationIcon', 'stationName', 'exerciseName', 'exerciseIntensity', 'exerciseComplexity', 'exerciseDiscipline', 'exerciseMuscularGroup', 'exerciseFundamentalMovement', 'exerciseImage', 'stationOptions'];
          break;
        case 2:
        case 3:
          workoutModeText = this.SET_TXT;
          workoutModeText = this.SERIE_TXT;
          columns = ['stationIcon', 'stationName', 'exerciseName', 'exerciseIntensity', 'exerciseComplexity', 'exerciseDiscipline', 'exerciseMuscularGroup', 'exerciseFundamentalMovement', 'exerciseImage', 'stationOptions'];
          break;
        case 4:
          workoutModeText = this.SET_TXT;
          workoutModeText = this.SERIE_TXT;
          columns = ['stationIcon', 'stationName', 'exerciseName', 'exerciseIntensity', 'exerciseComplexity', 'exerciseDiscipline', 'exerciseMuscularGroup', 'exerciseFundamentalMovement', 'reps', 'exerciseImage', 'stationOptions'];
          break;
      }

      this.displayedColumns = columns; ;
      this.__workoutModeTxt = workoutModeText;
      this.__workoutModeSubTxt = workoutModeSubText;

      this.refreshTimes();
    }
  }


  changeTotalRounds(change: number) {
    this.__totalRounds += change;
    this.__totalRounds = this.__totalRounds < 1 ? 1 : this.__totalRounds;

    if (this.__totalRounds > this.__session.rounds.length) {
      const newRound: ISessionRound = {
        id: this.__session.rounds.length + 1,
        session: this.__session.id,
        work: this.DEFAULT_WORK_TIME,
        rest: this.DEFAULT_REST_TIME,
        extraRest: this.DEFAULT_EXTRAREST_TIME
      };
      this.__session.rounds.push(newRound);
      this.__session.rounds.forEach((round, index) => {
        if (index !== this.__session.rounds.length - 1) {
          round.extraRest = round.extraRest > 0 ? round.extraRest : this.DEFAULT_EXTRAREST_TIME;
        } else {
          round.extraRest = 0;
        }
      });

      this.__session.moods.push({ id: this.__session.rounds.length * 1000 + 3, session: this.__session.id, mood: this.getMoodByRoundAndMode(null, 3).mood, mode: 3, round: this.__session.rounds.length });
      this.__session.moods.push({ id: this.__session.rounds.length * 1000 + 4, session: this.__session.id, mood: this.getMoodByRoundAndMode(null, 4).mood, mode: 4, round: this.__session.rounds.length });
      this.__session.moods.push({ id: this.__session.rounds.length * 1000 + 5, session: this.__session.id, mood: this.getMoodByRoundAndMode(null, 4).mood, mode: 5, round: this.__session.rounds.length });

      this.__session.playlists.push({ id: this.__session.rounds.length * 1000 + 3, session: this.__session.id, playlist: this.getPlaylistByRoundAndMode(0, 3) === undefined ? this.getPlaylistByRoundAndMode(null, 3).playlist : this.getPlaylistByRoundAndMode(0, 3).playlist, mode: 3, round: this.__session.rounds.length });
      this.__session.playlists.push({ id: this.__session.rounds.length * 1000 + 4, session: this.__session.id, playlist: this.getPlaylistByRoundAndMode(0, 4) === undefined ? this.getPlaylistByRoundAndMode(null, 4).playlist : this.getPlaylistByRoundAndMode(0, 4).playlist, mode: 4, round: this.__session.rounds.length });
      this.__session.playlists.push({ id: this.__session.rounds.length * 1000 + 5, session: this.__session.id, playlist: this.getPlaylistByRoundAndMode(0, 5) === undefined ? this.getPlaylistByRoundAndMode(null, 5).playlist : this.getPlaylistByRoundAndMode(0, 5).playlist, mode: 5, round: this.__session.rounds.length });
    }
    if (this.__totalRounds < this.__session.rounds.length) {
      this.__session.rounds.pop();
      this.__session.rounds[this.__session.rounds.length - 1].extraRest = 0;
      this.__session.moods.splice(-3, 3);
      this.__session.playlists.splice(-3, 3);
    }
  }


  recalculateTimes() {
    this.calculateTotalRestTime();
    this.calculateTotalWorkTime();
    this.calculateTotalTime();
  }


  onSubmit() {
    let formError: boolean = false;
    this.formSubmitted = true;

    this.checkMoods();

    if (!this.siteForm.form.valid || !this.allMoodsFilled() || !this.allPlaylistsFilled() || !this.workoutModeFilled() || !this.exercisesFilled()) {
      window.scroll(0, 0);
    } else {
      if (this.isNew) {
        this.createSession();
      } else {
        this.saveSession();
      }
    }
  }

  private checkMoods() {
    this.__session.moods.forEach((currentValue, index) => {
      if (currentValue.mood === 0) {
        this.__session.moods.splice(index, 1);
      }
    });
  }


  public saveSession() {

    // Clean temp properties
    let sendSession: ISession;
    sendSession = Object.assign({}, this.__session);
    delete sendSession.gymExercises;

    sendSession.exercises.forEach(e => {
      if(e.athleteAMRAP) {
        e.athleteReps = -1;
      }
      if(e.rookieAMRAP) {
        e.rookieReps = -1;
      }
    });

    sendSession.playlists = sendSession.playlists.filter(x => x.playlist != null);
    sendSession.moods = sendSession.moods.filter(x => x.mood != null);

    this.sessionsService.putSession(sendSession).subscribe(
      result => {
        this.notificationService.notify("Session saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  public createSession() {
    // Clean temp properties
    let sendSession: ISession;
    sendSession = Object.assign({}, this.__session);
    delete sendSession.gymExercises;

    sendSession.exercises.forEach(e => {
      if(e.athleteAMRAP) {
        e.athleteReps = -1;
      }
      if(e.rookieAMRAP) {
        e.rookieReps = -1;
      }
    });

    sendSession.playlists = sendSession.playlists.filter(x => x.playlist != null);
    sendSession.moods = sendSession.moods.filter(x => x.mood != null);

    this.sessionsService.postSession(sendSession).subscribe(
      result => {
        this.notificationService.notify("Session created successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.router.navigate(["/sessions"]);
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  changeProgram() {
    this.checkSessionRules();
    this.loadPlaylists();
  }


  imageSelected($event) {
    this.__session.image = $event;
  }


  imageRemoved() {

  }


  loadPlaylists() {
    if (this.__session) {
      this.playlistsService.getPlaylists(this.__session.program.id).subscribe(
        response => {
          if (response) {
            this.currentProgramPlaylists = response;
          }
        }, error => {
          this.notificationService.notify("An error occurred while retrieving playlists",
            ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
        }
      );
    }
  }


  refreshTimes() {
    if (this.__session.workoutMode.id >= 2 && this.__session.workoutMode.id <= 4) {
      for (const round of this.__session.rounds) {
        round.extraRest = this.__commonExtraRest;
      }
    }
  }


  getExercise(exerciseId: number): IExercise {
    return this.__session.gymExercises.filter(x => x.id === exerciseId)[0];
  }


  getExerciseByStationId(stationId: number): IExercise {
    const exercise = this.__session.exercises.filter(x => x.station === stationId)[0];
    let filtered;
    if (exercise !== undefined) {
      filtered = this.exercises.find(x => x.id === exercise.exercise || x.id === Number(exercise.exercise));
    }
    return filtered ? filtered : null;
  }


  getSessionExerciseByStationId(stationId: number): ISessionExercise {
    return this.__session.exercises.find(x => x.station === stationId);
  }


  isRepsEnabled(): boolean {
    for (let ex of this.__session.exercises) {
      if (ex.reps > 0) {
        return true;
      }
    }
    return false;
  }


  // Returns total round time
  calculateRoundTime(round: ISessionRound): number {
    let roundTime;
    if (this.__session.workoutMode.id === 1) {
      roundTime = this.getExerciseCount() * round.work + (this.getExerciseCount() - 1) * round.rest;
    }
    if (this.__session.workoutMode.id >= 2 && this.__session.workoutMode.id <= 4) {
      roundTime = this.getExerciseCount() * round.work + this.getExerciseCount() * round.rest;
    }
    return roundTime > 0 ? roundTime : 0;
  }


  // # of exercises, filtered by common stations (wall, floor and combo are the same)
  getExerciseCount() {
    let exerciseCount = this.__session.exercises.filter(x => x.station !== 6 && x.station !== 8 && x.station !== 9 && x.exercise).length;
    exerciseCount = this.__session.exercises.filter(x => (x.station === 6 || x.station === 8 || x.station === 9) && x.exercise).length > 0 ? exerciseCount + 1 : exerciseCount;
    return exerciseCount;
  }


  // Total time of session
  calculateTotalTime(): number {
    let workTime = this.calculateTotalWorkTime();
    let restTime = this.calculateTotalRestTime();
    let total = workTime + restTime;

    if(this.__session.sessionRepetitions && this.__session.sessionRepetitions > 1) {
      total *= this.__session.sessionRepetitions;

      total += (this.__session.sessionRepetitions-1) * this.__session.restBetweenSessionRepetitions || 0;
    }

    return total;
  }


  // Total work time in session
  calculateTotalWorkTime(): number {
    let totalTime = 0;
    for (const round of this.__session.rounds) {
      totalTime += this.getExerciseCount() * round.work;
    }
    return totalTime > 0 ? totalTime : 0;
  }


  // Tiempo total de descanso en la sesión
  calculateTotalRestTime(): number {
    let totalTime = 0;
    if (this.__session.workoutMode.id === 1) {
      for (const round of this.__session.rounds) {
        totalTime += (this.getExerciseCount() - 1) * round.rest;
        if (round.id !== this.__session.rounds[this.__session.rounds.length - 1].id) {
          totalTime += round.extraRest;
        }
      }
    }
    if (this.__session.workoutMode.id >= 2 && this.__session.workoutMode.id <= 4) {
      for (const round of this.__session.rounds) {
        if (round.id !== this.__session.rounds[this.__session.rounds.length - 1].id) {
          totalTime += (this.getExerciseCount() * round.rest);
        }
      }
      totalTime += (this.getExerciseCount() - 1) * this.__session.rounds[0].extraRest;
    }

    return totalTime > 0 ? totalTime : 0;
  }


  private isWorkoutModeChecked(workoutMode: IWorkoutMode) {
    return this.__session.workoutMode.id === workoutMode.id;
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }


  // Added as method. maybe we would prevent go back when there are pending changes
  goBack() {
    this.router.navigate(["/sessions"]);
  }


  stepperGoNext() {
    this.stepper.next();
  }


  stepperGoPrevious() {
    this.stepper.previous();
  }


  public isFirstStep() {
    if (this.stepper._steps !== undefined) {
      return this.stepper.selected === this.stepper._steps.first;
    } else {
      return true;
    }
  }


  public isLastStep() {
    if (this.stepper._steps !== undefined) {
      return this.stepper.selected === this.stepper._steps.last;
    } else {
      return true;
    }
  }


  public isWorkoutMode(workoutMode: number) {
    return this.__session.workoutMode.id === workoutMode;
  }


  public openSearchModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  public closeSearchModal() {
    this.modalRef.hide();
  }


  public allMoodsFilled(): boolean {
    return this.getMoodByRoundAndMode(null, 1).mood > 0 && this.getMoodByRoundAndMode(null, 1).mood !== 11
      && this.getMoodByRoundAndMode(null, 2).mood > 0 && this.getMoodByRoundAndMode(null, 2).mood !== 11
      && this.getMoodByRoundAndMode(null, 3).mood > 0 && this.getMoodByRoundAndMode(null, 3).mood !== 11
      && this.getMoodByRoundAndMode(null, 4).mood > 0 && this.getMoodByRoundAndMode(null, 4).mood !== 11;
  }


  public allPlaylistsFilled(): boolean {
    return this.getPlaylistByRoundAndMode(null, 2).playlist > 0
      && this.getPlaylistByRoundAndMode(null, 3).playlist > 0
      && this.getPlaylistByRoundAndMode(null, 4).playlist > 0
      && this.getPlaylistByRoundAndMode(null, 5).playlist > 0;
  }


  public workoutModeFilled(): boolean {
    return this.__session.workoutMode.id > 0;
  }


  public exercisesFilled(): boolean {
    return this.__session.exercises.length > 0;
  }

  rookieAMRAPChange(stationId) {
    const exercise = this.__session.exercises.find(x => x.station === stationId);
    if(exercise)
    {
      exercise.rookieAMRAP = !exercise.rookieAMRAP;

      if(exercise.rookieAMRAP) {
        exercise.rookieReps = undefined;
      }
    }
  }

  athleteAMRAPChange(stationId) {
    const exercise = this.__session.exercises.find(x => x.station === stationId);
    if(exercise)
    {
      exercise.athleteAMRAP = !exercise.athleteAMRAP;
      if(exercise.athleteAMRAP) {
        exercise.athleteReps = undefined;
      }
    }
  }

  private isChecked(list: any, item: any) {
    return list.filter(it => it === item).length > 0;
  }

  // If checkbox is changed turn it on/off depending on current status and add/remove from list
  // Also if option? = 'remove' always removes from list. Useful i.e: remove item in FundamentalMovement2 that is selected in FundamentalMovement
  changeItemFromList(itemList: any[], item: any, $event) {
      if (itemList.find(x => x.id === item.id)) {
        this.removeByKey(itemList, { key: 'id', value: item.id });
      } else {
        itemList.push(item);
      }
  }

  public removeByKey(array, params) {
    array.some(function (item, index) {
      return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }
}
