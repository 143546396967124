import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import { ModalRemoveCategoryComponent } from './modal-remove-category.component';
import { CategoriesService } from '../../services/categories.service';
import { Category } from '../../interfaces/models/category';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';
import { MatCardTitleGroup } from '@angular/material/card';


@Component({
  selector: 'categories-manager',
  templateUrl: './categories-manager.component.html',
  styleUrls: ['./categories-manager.component.css']
})
export class CategoriesManagerComponent implements OnInit {

  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;
  newMode: boolean;
  editMode: number;

  newCategory: Category;

  headers = {
    name: "Name",
    actions: "Actions"
  };

  categoryList: Category[];
  categoryListToShow: Category[];
  categoryTree: Category[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['name', 'actions'];
  dataSource = new MatTableDataSource;


  constructor(public route: ActivatedRoute, private router: Router,
    private categoriesService: CategoriesService,
    public dialog: MatDialog,
    private notificationService: NotificationService) {

    this.categoryTree = this.route.snapshot.data.categories;
    this.categoryList = this.categoriesService.convertTreeToList(this.route.snapshot.data.categories);
    this.categoryListToShow = this.categoriesService.convertTreeToList(this.route.snapshot.data.categories);
    // roles
    this.canModify = true;
    this.canCreate = true;
    this.canDelete = true;
  }


  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

  }


  ngAfterViewInit() {
    this.updateTableData();
  }


  updateTableData() {
    this.dataSource = new MatTableDataSource(this.categoriesService.sortListByName(this.categoryListToShow));
  }


  openEditor(category: Category) {
    this.editMode = category.id;
    if (this.newMode) {
      this.categoryListToShow = this.categoryListToShow.filter(x => x.id != 0);
    }
    this.dataSource = new MatTableDataSource(this.categoryListToShow);
  }


  scrollTableToTop() {
    try {
      const matTable = document.getElementById('categoryTable');
      matTable.scrollTop = 0;
    } catch (err) {
      console.log(err);
    }
  }


  openCreateCategory() {
    this.editMode = 0;
    if (this.newMode) {
      this.categoryListToShow = this.categoryListToShow.filter(x => x.id != 0);
    }
    this.newMode = true;
    this.newCategory = {
      id: 0,
      name: '',
      fullpath: '',
      idChain: 0,
      idParent: null,
      categories: [],
      workouts: 0
    };
    this.categoryListToShow.unshift(this.newCategory);
    this.dataSource = new MatTableDataSource(this.categoryListToShow);

    this.scrollTableToTop();
  }


  createCategory(category: Category) {
    if (this.newCategory.idParent !== null) {

      if (this.newCategory.idParent === 0) {
        this.newCategory.idParent = null;
      }

      this.categoriesService.createCategory(category).subscribe(
        result => {
          this.notificationService.notify("Category saved successfully",
            ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
          this.resetForm(event, false);
        },
        error => {
          console.log('Error', error);
        }
      );
    }
  }


  saveCategory(category: Category) {
    this.categoriesService.updateCategory(category).subscribe(
      result => {
        this.notificationService.notify("Category modified successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.resetForm(event, false);
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  resetForm(event, canceled) {
    try {
      event.stopPropagation();
    } catch (e) {
    }

    if (canceled) {
      this.editMode = 0;
      this.newMode = false;
      this.categoryListToShow = this.categoriesService.convertTreeToList(this.categoryTree);
      this.dataSource = new MatTableDataSource(this.categoryListToShow);
    }
    else {
      this.categoriesService.getCategories().subscribe(
        result => {
          this.categoryTree = result;
          this.categoryList = this.categoriesService.convertTreeToList(result);
          this.categoryListToShow = this.categoriesService.convertTreeToList(result);
          this.editMode = 0;
          this.newMode = false;
          this.dataSource = new MatTableDataSource(this.categoryListToShow);
        },
        error => {
          console.log('Error');
        }
      );
    }
  }

  modalRemoveCategoryOpen(category: Category) {
    let modal = this.dialog.open(ModalRemoveCategoryComponent, {
      data: {
        category: category,
        categoryList: this.categoryListToShow
      }
    });
    modal.componentInstance.onDelete.subscribe( val => {
      //this.categoryListToShow = this.categoryListToShow.filter( x => x.id != val.id);
      //this.updateTableData();
      this.resetForm(event, false);
    })
  }

  canDeleteCategory(category: Category) {
    return (category.workouts == 0 || category.workouts == null) && (category.categories == null || category.categories.length == 0);
  }

  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }
}
