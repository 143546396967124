import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { IExercise, IExerciseSessionTable } from "../../interfaces/models/i-exercise";

import { CONFIG } from "../../../assets/config";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from "../../services/auth.service";
import { ENUMS } from "../../../assets/enums";

@Component({
  selector: "exercise-list",
  templateUrl: "./exercise-list.component.html",
  styleUrls: ["./exercise-list.component.css"]
})
export class ExerciseListComponent implements OnInit, AfterViewInit {

  headers = {
    id: "Id",
    name: "Name",
    desc: "Description",
    station: "Station",
    players: "Players",
    color: "Color",
    thumbnail: " ",
    type: "Type",
    strengthLevel: "Strength",
    cardioLevel: "Cardio",
    complexityLevel: "Complexity",
    creationDate: "Created on",
    actions: "",
    status: "Status",
    program: "Program",
    muscularGroup: "Muscular group",
    intensity: "Intensity",
    accessories: "Accessories"
  };

  exercises: IExercise[];
  exercisesToShow: IExercise[];

  exerciseList: IExercise[] = [];
  exerciseListRange: number = 0;
  exerciseListAddOnEachScroll: number = 10;

  mediaUrl: string = CONFIG.exercisesMediaURL;
  exercisesMediaURL: string = CONFIG.exercisesMediaURL;
  previewImage = "";

  pavigym: boolean;
  userId: number;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Exercise list
  // displayedColumns = ['id', 'name', 'station', 'program', 'muscularGroup', 'intensity', 'equipment', 'thumbnail'];
  displayedColumns = ['stationIcon', 'id', 'name', 'program', 'intensityLevel', 'complexityLevel', 'muscularGroup1', 'fundamentalMovement1', 'exerciseImage'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
    this.exercisesToShow = this.route.snapshot.data.exercises;
    this.pavigym = this.authService.isInRole(ENUMS.roles.pavigym);
    this.userId = this.authService.getUserId();

    //this.exercisesToShow = this.exercisesToShow.slice(0, 40); // Limit to 40 for debugging
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });    
  }

  ngAfterViewInit() {
    // for (const ex of this.exercisesToShow) {
    //   this.exerciseList.push(ex);
    // }
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.updateTableData();    
  }

  loadDataOnTable() {
    for (let i = this.exerciseListRange; i < this.exerciseListRange + this.exerciseListAddOnEachScroll; i++) {
      // this.exerciseList.push(this.exercisesToShow[i]);
    }
    this.exerciseListRange += this.exerciseListAddOnEachScroll;
  }

  showPreview(exercise: IExercise) {
    this.previewImage = (this.mediaUrl + exercise.thumbnail).toString();
  }
  
  updateTableData(){
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.exerciseList);    
    this.dataSource.paginator = this.paginator;
  }

  createExercise() {
    let url = this.authService.isInRole(ENUMS.roles.pavigym) ? '/exercise' : '/exercise-hf';
    this.router.navigate([url, 'new']);
  }

  editExercise(exercise) {
    let navigate = false;
    let url = "";
    if (this.pavigym && exercise.userId == 0) {
      url = '/exercise';
      navigate = true;
    } else if(!this.pavigym && exercise.userId == this.userId){
      url = '/exercise-hf';
      navigate = true;
    }

    if(navigate) {
      this.router.navigate([url, exercise.id]);  
    }
  }

}
