import { LanguageService } from './../../../services/language.service';
import { DialogEditBlockComponent } from './dialog-edit-block/dialog-edit-block.component';
import { MatDialog } from '@angular/material/dialog';
import { EChannel, EBlockCategory, EBlock, ETimeUnitBlock } from './../../../interfaces/models/block-editor';
import { Input, OnChanges, TemplateRef } from '@angular/core';
import { IProgram } from './../../../interfaces/models/i-program';
import { Workout } from './../../../interfaces/models/blockworkout';
import { ViewChild, ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DragStartEvent } from 'angular-draggable-droppable';
import { ResizeEvent } from 'angular-resizable-element';
import { IExercise } from './../../../interfaces/models/i-exercise';
import { ActivatedRoute } from '@angular/router';

import { CONFIG } from '../../../../assets/config';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LayoutDirective } from '@angular/flex-layout';

import { BLOCK_CONFIG } from './block-editor-config';

@Component({
  selector: 'block-editor',
  templateUrl: './block-editor.component.html',
  styleUrls: ['./block-editor.component.scss']
})
export class BlockEditorComponent implements OnInit, OnChanges {

  @ViewChild('ghostEl') ghostEl: ElementRef<any>;

  @Input() workout: Workout;
  @Input() programs: IProgram[];
  @Input() exercises: IExercise[];

  public BLOCK_CONFIG = BLOCK_CONFIG;

  // Canales
  //public workout.channels: EChannel[];

  public blockResources: EBlockCategory[];

  // Plantillas / Bloques
  public channelList: EChannel[];
  //public blockList: EBlock[];
  public blockMusicList: EBlock[];
  public blockTagList: EBlock[];
  public timelineLabels: ETimeUnitBlock[];
  public timelineGridBlocks: ETimeUnitBlock[];

  dragOverData: string;
  public dropEnabled: boolean = false;

  snapDataDrag: {
    x: number, y: number;
  }
  snapDataResize: { left: number, right: number }
  resizing: boolean;
  newDuration: number;
  labelDurationMult: number;

  channelWidth: number;

  isOverChannel: boolean;
  blockEditorContainer;

  blockIdIndex: number = -1; // Indice de bloque para nuevas creaciones

  public globalDuration: number;
  public gridSnapX = this.BLOCK_CONFIG.GRID_SNAP_INITIAL_X;
  public gridSnapY = this.BLOCK_CONFIG.GRID_SNAP_INITIAL_Y;
  public gridZoom: number;

  public workoutDuration;

  modalRef: BsModalRef[] = [];
  currentModalIndex: number = 0;

  currentBlock: EBlock;

  public exercisesMediaURL = CONFIG.exercisesMediaURL;

  constructor(public dialog: MatDialog,
    public languageService: LanguageService,
    public route: ActivatedRoute,
    private modalService: BsModalService) {
      
      this.gridZoom = this.BLOCK_CONFIG.GRID_ZOOM_DEFAULT;
  }

  ngOnInit() {

    this.snapDataDrag = {
      x: 0,
      y: 0
    };
    this.snapDataResize = {
      left: this.gridSnapX,
      right: this.gridSnapX
    }

    this.channelList = [
      { id: -3, name: "Tags", blocks: [], type: 3, blockTypes: [6] },
      { id: -1, name: "Execution", blocks: [], type: 1, blockTypes: [1, 2, 3] },
      // { id: -2, name: "Music", blocks: [], type: 2, blockTypes: [4] }
    ];

    // BLOCK TYPES
    // 1 exercise
    // 2 video
    // 3 layer
    // 4 playlist
    // 5 mood
    // 6 tag

    // TAG TYPES
    // 1 Phase (value: string)
    // 2 CurrentExercise (value: int)
    // 3 TotalExercises (value: int)
    // 4 CurrentRound (value: int)
    // 5 TotalRounds (value: int)
    // 6 WorkoutMode (value: string)

    this.blockResources = [
      { id: 1, name: "Standard", blocks: this.BLOCK_CONFIG.blockList.filter(b => this.channelList.find(c => c.id === -1).blockTypes.includes(b.type)) },
      { id: 2, name: "Tags", blocks: this.BLOCK_CONFIG.blockList.filter(b => this.channelList.find(c => c.id === -3).blockTypes.includes(b.type)) },
      // { id: 3, name: "Music", blocks: this.blockList.filter(b => this.channelList[1].blockTypes.includes(b.type)) }
    ];

    // Inicializamos variables
    this.resizing = false;
    this.newDuration = 0;
    this.isOverChannel = false;
    this.globalDuration = 0;
    this.timelineLabels = [];
    this.timelineGridBlocks = [];
    this.labelDurationMult = 1;

    this.initializeData();
  }

  public initializeData() {
    this.initializeChannels();
    this.classifyBlocks();
    this.calculateDuration();
    this.setGridZoom(0);
    this.processTimelineLabels();
    this.processTimelineGrid();
    this.calculateWorkoutDuration();
    this.assignSessionTotalTime();
    this.updateStickedBlocks();
  }

  ngOnChanges() {
    this.ngOnInit();
    console.log(this.workout);
  }

  // Control de FIN de arrastre
  dragEnd(event: DragEvent, refElem: any, block: EBlock) {
  }

  // Controlamos zona de drop
  public dropOnZone($event, channel: EChannel, channelDropZone) {
    const ghostCoords = this.ghostEl.nativeElement.parentNode.getBoundingClientRect();
    const channelCoords = channelDropZone.parentNode.getBoundingClientRect();
    if (!this.resizing && this.isOverChannel) {
      this.processBlockDrop($event.dropData, channel, channelCoords, ghostCoords);
    }
    //const { top, left } = this.ghostEl.nativeElement.parentNode.getBoundingClientRect();
  }

  // Controlamos que el cursor esté en la ventana de drop (channels), se llama desde onmouseover de contenedor
  public dropEnableSet(value: boolean) {
    this.dropEnabled = value;
  }


  // Procesa bloque suelto en zona de drop
  private processBlockDrop(block: EBlock, channel: EChannel, channelCoords, ghostCoords) {

    // Si el canal permite este tipo de bloque, continuamos
    if (channel.blockTypes.includes(block.type) && this.dropEnabled) {

      let newBlock;
      // Si es nuevo bloque
      if (!block.channel) {
        //newBlock = Object.assign({}, block);

        // Deep Clone para evitar referenciado
        newBlock = JSON.parse(JSON.stringify(block));
        newBlock.id = this.blockIdIndex;
        this.blockIdIndex--;

        // Si es tag, inicializa su item
        // if(newBlock.type === 6){
        //   newBlock.item.id = this.blockIdIndex;
        //   this.blockIdIndex--;
        // }
      } else {
        newBlock = block;
      }

      // Verificamos si el bloque cambia de channel
      // Si cambia de channel
      if (!newBlock.channel || channel.id !== newBlock.channel) {
        // Añadir al nuevo
        this.workout.channels.find(c => c.id === channel.id).blocks.push(newBlock);

        // Eliminar del antiguo
        if (newBlock.channel) {
          let oldChannel = this.workout.channels.find(c => c.id === newBlock.channel);
          if (oldChannel) {
            let index = oldChannel.blocks.indexOf(newBlock);
            oldChannel.blocks.splice(index, 1);
          }
        } else {
          // const newBlock = Object.assign({}, block);
          // console.log(block);
        }
      }
      newBlock.channel = channel.id;

      // Calculamos positionX
      const newPositionX = (ghostCoords.left - channelCoords.left - this.BLOCK_CONFIG.CHANNEL_HEADER_WIDTH) / this.gridSnapX;
      newBlock.positionX = newPositionX;
      this.snapBlock(newBlock);

      // Asignamos valores de blocks
      newBlock.initTime = newBlock.positionX;
      // Si no es un bloque tipo Tags, controlamos overlapings
      if (block.type !== 6) {
        this.fixBlocksOverlaps(block);
      }

      // Repasamos stickedBlocks de cada bloque de ese channel
      this.channelUpdateStickedBlocksByBlock(newBlock);

      // Actualizamos WorkoutDuration
      this.calculateWorkoutDuration();
      this.calculateDuration();
      this.processTimelineLabels();
      this.recalculateChannelWidth();
      this.assignSessionTotalTime();
    }
  }

  // Ajustamos bloque a Snap más cercano
  private snapBlock(block: EBlock) {
    let snappedX = Math.round(block.positionX / (this.gridSnapX / this.gridZoom)) * (this.gridSnapX / this.gridZoom);
    let snappedY = Math.round(block.positionY / this.gridSnapY) * this.gridSnapY;

    block.positionX = snappedX;
    block.positionY = snappedY;

    if (block.positionX < 0) {
      block.positionX = 0;
    }
    //Math.round(moveData.transformX / _this.dragSnapGrid.x) * _this.dragSnapGrid.x;
  }

  // Control de Bloque cuando entra en zona
  dragOver(channel: EChannel, $event, channelDropZone) {
    //this.snapDataDrag = { x: this.gridSnapX, y: this.gridSnapY };
    channel.dragOverData = $event.dropData;
    this.isOverChannel = true;
  }

  // Control de Bloque cuando abandona zona
  dragLeave(channel: EChannel, $event) {
    channel.dragOverData = "";
    this.isOverChannel = false;
  }

  // Control de bloque siendo arrastrado
  dragging(block: EBlock) {
  }

  // Control de COMIENZO de resize
  onResizeStart(event: ResizeEvent, block: EBlock) {
    this.resizing = true;
    block.stickedBlocks = this.getStickedBlocks(block);
  }

  // Control de FIN de resize
  onResizeEnd(event: ResizeEvent, refElem: any, block: EBlock): void {
    //block.duration = Math.round(event.rectangle.width / this.gridSnapX);
    block.stickedBlocks = this.getStickedBlocks(block);

    // Repasamos stickedBlocks de cada bloque de ese channel
    this.channelUpdateStickedBlocksByBlock(block);

    // Timeout para evitar solapamiento con evento de captura de ratón de DRAG
    setTimeout(() => {
      this.resizing = false;
    }, 10);

    this.calculateDuration();
    this.processTimelineLabels();
    this.processTimelineGrid();

    // Actualizamos WorkoutDuration
    this.calculateWorkoutDuration();
    this.calculateDuration();
    this.processTimelineLabels();
    this.recalculateChannelWidth();
    this.assignSessionTotalTime();
  }

  // Control de RESIZING
  onResizing(event: ResizeEvent, block: EBlock) {
    block.duration = Math.round(event.rectangle.width / this.gridSnapX);
    block.duration = block.duration < this.BLOCK_CONFIG.GRID_BLOCK_MIN_DURATION ? this.BLOCK_CONFIG.GRID_BLOCK_MIN_DURATION : block.duration;

    // Si no es un bloque tipo Tags, controlamos overlapings
    if (block.type !== 6) {
      // Controlamos resize positivo, si encuentra un bloque, lo moverá con él
      this.fixBlocksOverlaps(block);

      // Controlamos resize negativo, si tiene un bloque adyacente (stickBlock), lo moverá con él
      if (block.stickedBlocks) {
        this.moveStickedBlocks(block);
      }
    }

  }

  // Obtener width de bloque en pixeles según gridSnapX
  public getBlockWidth(block: EBlock): number {
    return block.duration * this.gridSnapX;
  }

  // Recalcular globalDuration
  public calculateDuration() {
    this.globalDuration = this.globalDuration > this.getLastBlockPosition() + this.BLOCK_CONFIG.THRESHOLD_TIMELINE_GROW ? this.globalDuration : this.getLastBlockPosition() + this.BLOCK_CONFIG.THRESHOLD_TIMELINE_GROW + this.BLOCK_CONFIG.TIME_INCREASE_ON_TIMELINE_GROW;
    this.globalDuration = this.globalDuration < this.BLOCK_CONFIG.DRAWER_MIN_DURATION ? this.BLOCK_CONFIG.DRAWER_MIN_DURATION : this.globalDuration;
  }

  // Devuelve un int con la última posición de tiempo con bloque ocupado
  public getLastBlockPosition() {
    let position = 0;
    this.workout.channels.forEach(channel => {
      channel.blocks.forEach(block => {
        const blockPos = block.initTime + block.duration;
        position = position > blockPos ? position : blockPos;
      });
    });
    return position;
  }

  // Procesar linea de labels timeline
  private processTimelineLabels() {

    this.timelineLabels.length = 0;
    let lastLabel;
    do {
      const newLabel: ETimeUnitBlock = {
        id: 0,
        label: '',
        initTime: lastLabel !== undefined ? (lastLabel.initTime + lastLabel.duration) : this.BLOCK_CONFIG.LABEL_DEFAULT_START_TIME,
        duration: this.BLOCK_CONFIG.LABEL_DEFAULT_DURATION * this.labelDurationMult,
        positionX: lastLabel !== undefined ? (lastLabel.initTime + lastLabel.duration) : this.BLOCK_CONFIG.LABEL_DEFAULT_START_TIME
      };
      newLabel.label = this.getFormattedTimeFromSeconds(newLabel.initTime).toString();

      this.timelineLabels.push(newLabel);
      lastLabel = this.timelineLabels[this.timelineLabels.length - 1];
    } while (lastLabel.initTime + lastLabel.duration < this.globalDuration)
  }

  // Procesar casillas de grid
  private processTimelineGrid() {

    let lastBlock;
    do {
      const newBlock: ETimeUnitBlock = {
        id: 0,
        label: '',
        initTime: lastBlock !== undefined ? lastBlock.initTime + lastBlock.duration : this.BLOCK_CONFIG.GRID_BLOCK_DEFAULT_START_TIME,
        duration: this.BLOCK_CONFIG.GRID_BLOCK_DEFAULT_DURATION,
        positionX: lastBlock !== undefined ? (lastBlock.initTime + lastBlock.duration) : this.BLOCK_CONFIG.GRID_BLOCK_DEFAULT_START_TIME
      };
      newBlock.label = this.getFormattedTimeFromSeconds(newBlock.initTime).toString();

      this.timelineGridBlocks.push(newBlock);
      lastBlock = this.timelineGridBlocks[this.timelineGridBlocks.length - 1];
    } while (lastBlock.initTime + lastBlock.duration < this.globalDuration)
  }

  // Calcular tiempo para labels
  private getFormattedTimeFromSeconds(timeInSeconds: number) {
    let date = new Date(0);
    date.setHours(0);
    date.setSeconds(timeInSeconds);
    let resultTime;
    if (timeInSeconds >= 3600) {
      resultTime = date.toTimeString().split(' ')[0];
    } else {
      resultTime = date.toTimeString().split(' ')[0].slice(3);
    }
    return resultTime;
  }

  // Modificar zoom in / zoom out de Grid
  public setGridZoom(zoomModify: number) { // +1 aumenta, -1 disminuye, 0 refresca
    this.gridZoom += zoomModify;
    this.gridZoom = this.gridZoom < this.BLOCK_CONFIG.GRID_ZOOM_MIN_LEVEL ? this.BLOCK_CONFIG.GRID_ZOOM_MIN_LEVEL : this.gridZoom;
    this.gridZoom = this.gridZoom > this.BLOCK_CONFIG.GRID_ZOOM_MAX_LEVEL ? this.BLOCK_CONFIG.GRID_ZOOM_MAX_LEVEL : this.gridZoom;

    this.gridSnapX = this.BLOCK_CONFIG.GRID_SNAP_INITIAL_X * this.gridZoom;

    //Modificamos Snap de Resize
    this.snapDataResize = {
      left: this.gridSnapX * this.BLOCK_CONFIG.GRID_BLOCK_RESIZE_SNAP_X,
      right: this.gridSnapX * this.BLOCK_CONFIG.GRID_BLOCK_RESIZE_SNAP_X
    }

    this.recalculateChannelWidth();

    // Asignamos espaciado de labels según zoom
    if (this.gridZoom <= 1) {
      this.labelDurationMult = 6;
    } else
      if (this.gridZoom <= 2) {
        this.labelDurationMult = 3;
      } else
        if (this.gridZoom <= 3) {
          this.labelDurationMult = 2;
        } else
          if (this.gridZoom <= 4) {
            this.labelDurationMult = 1;
          } else
            if (this.gridZoom <= 5) {
              this.labelDurationMult = 1;
            } else
              if (this.gridZoom <= 6) {
                this.labelDurationMult = 1;
              }

    this.processTimelineLabels();
  }

  public recalculateChannelWidth() {
    this.channelWidth = this.globalDuration * this.BLOCK_CONFIG.GRID_SNAP_INITIAL_X * this.gridZoom + this.BLOCK_CONFIG.CHANNEL_HEADER_WIDTH;
  }

  // Borrar un bloque de un canal
  public removeBlock(channel: EChannel, block: EBlock) {
    let index = channel.blocks.indexOf(block);
    channel.blocks.splice(index, 1);
  }

  public modalDeleteBlock(block: EBlock) {
    this.removeBlock(this.getChannelByBlockId(block.id), block);
    this.closeModal();
  }

  // Obtenemos bloque dado un Id
  public getBlockById(id: number) {
    let blockFound = null;
    this.workout.channels.forEach(channel => {
      channel.blocks.forEach(block => {
        if (block.id === id) {
          blockFound = block;
        }
      });
    });
    return blockFound;
  }

  // Obtenemos channel de un id de bloque (OPTIMIZAR con un every o similar)
  public getChannelByBlockId(id: number) {
    let foundChannel = null;
    this.workout.channels.forEach(channel => {
      channel.blocks.forEach(block => {
        if (block.id === id) {
          foundChannel = channel;
        }
      });
    });
    return foundChannel;
  }

  // Obtenemos siguientes bloques contiguos en el canal a uno dado
  public getStickedBlocks(block: EBlock) {
    // let stickedBlocks = []; // Inicializamos array
    let stickedBlock;
    if (block) {
      let channel = this.getChannelByBlockId(block.id);
      for (let i = 0; i < channel.blocks.length; i++) { // Recorremos channel del bloque
        if (channel.blocks[i].initTime > block.initTime && channel.blocks[i].initTime <= block.initTime + block.duration) { // Si el bloque encontrado es stickBlock
          //stickedBlocks.push(channel.blocks[i].id); // Lo añadimos al array
          stickedBlock = channel.blocks[i].id; // Lo añadimos al array
          //stickedBlocks.push(...this.getStickedBlocks(channel.blocks[i])); // Y llamamos recursivamente para buscar stickBlock de éste
          return stickedBlock;
        }
      }
    }
    return stickedBlock; // Si no encuentra más, volvemos
  }

  // Movemos stickedBlock de un bloque al hacer Resize
  public moveStickedBlocks(block: EBlock) {
    let lastBlock = block;
    // block.stickedBlocks.forEach(stickedBlock => {
    //   stickedBlock.initTime = stickedBlock.positionX = lastBlock.initTime + lastBlock.duration;
    //   lastBlock = stickedBlock;
    //   this.snapBlock(stickedBlock);
    // });

    if (block.stickedBlocks) {
      let stickedBlock = this.getBlockById(block.stickedBlocks);
      stickedBlock.initTime = stickedBlock.positionX = lastBlock.initTime + lastBlock.duration;
      lastBlock = stickedBlock;
      this.snapBlock(stickedBlock);

      this.moveStickedBlocks(stickedBlock);
    }
  }

  // Corregimos todos los posibles bloques solapados
  public fixAllOverlapings() {
    this.workout.channels.forEach(channel => {
      channel.blocks.forEach(block => {
        this.fixBlocksOverlaps(block);
      });
    });
  }

  // Corregimos solo un canal dado un bloque
  public fixBlocksOverlaps(block: EBlock) {
    let channel = this.getChannelByBlockId(block.id);
    if (channel) {
      while (this.hasOverlapings(channel)) {
        for (let i = 0; i < channel.blocks.length; i++) {
          for (let j = 0; j < channel.blocks.length; j++) {
            this.fixOverlapingBlock(channel.blocks[i], channel.blocks[j]);
          }
        }
      }
    }
  }

  // Metodo auxiliar: devuelve true si existen overlapings en un canal dado
  public hasOverlapings(channel: EChannel): boolean {
    for (let i = 0; i < channel.blocks.length; i++) {
      for (let j = 0; j < channel.blocks.length; j++) {
        if (channel.blocks[j].initTime >= channel.blocks[i].initTime && channel.blocks[j].initTime < channel.blocks[i].initTime + channel.blocks[i].duration && channel.blocks[j].id !== channel.blocks[i].id) {
          return true;
        }
      }
    }
    return false;
  }

  // Corregimos bloque findBlock si esta solapando block [-----1--{==]-2-----}
  public fixOverlapingBlock(block: EBlock, findBlock: EBlock) {
    if (findBlock.initTime >= block.initTime && findBlock.initTime < block.initTime + block.duration && findBlock.id !== block.id) {
      findBlock.initTime = block.initTime + block.duration;
      findBlock.positionX = findBlock.initTime;
      this.snapBlock(findBlock);
    }
  }

  // Desenlaza bloque del siguiente, restandole un grid block (5s)
  public unlinkBlock(block: EBlock, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (block.duration > this.BLOCK_CONFIG.GRID_BLOCK_DEFAULT_DURATION) {
      block.duration -= this.BLOCK_CONFIG.GRID_BLOCK_DEFAULT_DURATION;
    }
    block.stickedBlocks = null;
  }

  // Actualizamos stickedBlocks de cada bloque de un canal dado un block
  public channelUpdateStickedBlocksByBlock(block: EBlock) {
    let channel = this.getChannelByBlockId(block.id);
    this.channelUpdateStickedBlocksByChannel(channel);
  }

  // Actualizamos stickedBlocks de cada bloque de un canal dado un channel
  public channelUpdateStickedBlocksByChannel(channel: EChannel) {
    if (channel) {
      channel.blocks.forEach(b => {
        b.stickedBlocks = this.getStickedBlocks(b);
      });
    }
  }

  // Actualizamos stickedBlocks de todo el Workout
  public updateStickedBlocks() {
    this.workout.channels.forEach(channel => {
      this.channelUpdateStickedBlocksByChannel(channel);
    });
  }

  // Clasificamos bloques y asignamos estilo
  public classifyBlocks() {
    this.workout.channels.forEach(channel => {
      channel.blocks.forEach(block => {
        this.BLOCK_CONFIG.blockList.filter(blockType => {
          if (blockType.type === block.type && blockType.item?.behaviour === block.item?.behaviour && blockType.item?.type === block.item?.type) {
            block.style = blockType.style;
            block.icon = blockType.icon;
            block.name = blockType.name;
            block.order = blockType.order;

            // Name del block
            if (block.item?.name) {
              block.name = block.item?.name;
            }
          }
        })
      });
    });
  }

  // Inicializamos Channels
  // Toma como referencia la plantilla
  // TAmbién creamos canales por defecto si no existen
  public initializeChannels() {
    this.channelList.forEach(channelInList => {
      let channelFound = this.workout.channels.find(channel => channel.type === channelInList.type);
      if (channelFound === undefined) {
        // Añadimos canales faltantes
        this.workout.channels.push(channelInList);
      } else {
        // Si existe, asignamos nombre de channel de plantilla
        channelFound.name = channelInList.name;
        channelFound.blockTypes = channelInList.blockTypes;
      }
    });
  }

  public openModal(template: TemplateRef<any>, block?: EBlock) {
    this.currentBlock = block ? block : this.currentBlock;
    this.modalRef[this.currentModalIndex] = this.modalService.show(template, Object.assign({}, { class: 'modal-dialog-centered' }));
    this.currentModalIndex++;
  }


  public closeModal() {
    this.currentModalIndex--;
    this.modalRef[this.currentModalIndex].hide();
  }

  // Evento de ejercicio seleccionado
  public exerciseSelected(exercise: IExercise) {
    // this.currentBlock.item = exercise;

    this.currentBlock.item = {
      id: exercise.id,
      image: exercise.image,
      name: exercise.name
    };

    console.log(this.workout);
  }

  // Calculamos tiempo total de sesion (NO CONFUNDIR CON workout.duration)
  public calculateWorkoutDuration() {
    let newDuration = 1;
    this.workout.channels.forEach(channel => {
      channel.blocks.forEach(block => {
        newDuration = block.initTime + block.duration > newDuration ? block.initTime + block.duration : newDuration;
      });
    });
    this.workoutDuration = newDuration;
  }

  // Asignamos duration del workout
  public assignSessionTotalTime() {
    this.workout.duration = this.getTotalBlocksTime('all');
  }


  // Calculamos y devolvemos tiempo total del tipo de bloques dado en el selector(work, rest)
  public getTotalBlocksTime(selector: string) {
    let workoutChannel = this.workout.channels.find(channel => channel.type === 1);
    let totalTime = 0;
    if (workoutChannel) {
      workoutChannel.blocks.forEach(block => {
        switch (selector) {
          case 'work':
            if (block.type === 1) {
              totalTime += block.duration;
            }
            break;

          case 'rest':
            if (block.type === 3 && block.item?.behaviour === 0) {
              totalTime += block.duration;
            }
            break;

          case 'all':
            totalTime = totalTime > block.initTime + block.duration ? totalTime : block.initTime + block.duration;
            break;
          default: break;
        }
      });

    }
    return totalTime;
  }

  public calculateBlockVerticalOffset(block: EBlock) {
    if (block.type === 6) {
      return this.BLOCK_CONFIG.BLOCK_VERTICAL_OFFSET_INSIDE_CHANNEL + this.BLOCK_CONFIG.BLOCK_VERTICAL_OFFSET_TAG * (block.order - 1);
    }
    return this.BLOCK_CONFIG.BLOCK_VERTICAL_OFFSET_INSIDE_CHANNEL;
  }

  public isTagBlock(block: EBlock) {
    return block.type === 6;
  }
  public isTagChannel(channel: EChannel) {
    return channel.type === 3;
  }

  // Obtiene abreviaturas (solo mayusculas) de un string
  public shortBlockTitle(title: string) {
    return title.replace(/[a-z ]/g, '');
  }


}
